import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AssetsHelperService {

  constructor() { }

  public getBaseUrl(): string {
    return document.getElementsByTagName('base')[0].href;
  }

  public getRessourceUrl(path: string): string {
    return this.getBaseUrl() + 'assets/' + path;
  }

  public getImageUrl(path: string): string {
    return this.getRessourceUrl('images/' + path);
  }

  public getIconUrl(path: string): string {
    return this.getRessourceUrl('icons/' + path);
  }
}
