import { NgModule} from '@angular/core';
import { AppMaterialModule } from 'apis-helpers';

import { AppComponentsModule } from './components.module';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { OperatorDialogComponent } from '../dialogs/operator-dialog/operator-dialog.component';

@NgModule({
  imports: [
    AppMaterialModule,
    AppComponentsModule,
    CommonModule
  ],
  declarations: [
    ConfirmDialogComponent,
    OperatorDialogComponent
  ]
})
export class AppDialogsModule {}
