import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import {catchError, mergeMap, Observable, throwError} from 'rxjs';
import { KeycloakTokenService } from '../auth-keycloak/services/auth-keycloak-init.service';
import { ConfigurationProvider } from '../config';

@Injectable()
export class KeycloakInterceptor implements HttpInterceptor {

  constructor(
    private configService: ConfigurationProvider, 
    private keycloakTokenService: KeycloakTokenService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.configService.params.authKeycloakServer) {
      if (this.keycloakTokenService.isAuthenticated) {
        return this.keycloakTokenService.getAccessToken().pipe(mergeMap(token => {
            const authenticatedRequest = this.authenticate(request, token);
            return next.handle(authenticatedRequest);
        }));
      } else {
        return next.handle(request).pipe(catchError(error => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            this.keycloakTokenService.login();
          } else {
            return throwError(error);
          }
        }));
      }
    } else {
      return next.handle(request);
    }
  }

  private authenticate<T>(request: HttpRequest<T>, token: string): HttpRequest<T> {
    return request.clone({headers: request.headers.set('Authorization', `Bearer ${token}`)});
  }
}
