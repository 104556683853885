import { NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";
import { AcronymPipe } from './acronym.pipe';


@NgModule({
  declarations:[
    AcronymPipe
  ],
  imports:[CommonModule],
  exports:[
    AcronymPipe
  ]
})
export class PipesModule {
  static forRoot() {
    return {
        ngModule: PipesModule
    };
 }
}
