import { Component, Input, OnInit } from "@angular/core";
import { ControlContainer, UntypedFormGroup } from "@angular/forms";

@Component({
  selector: "frmk-text-field",
  templateUrl: "./text-field.component.html",
  styleUrls: ["./text-field.component.scss"],
})
export class TextFieldComponent implements OnInit {
  formGroup: UntypedFormGroup;
  @Input() label: string;
  @Input() controlName: string;

  constructor(private controlContainer: ControlContainer) {}

  get control() { 
    return this.formGroup.get(this.controlName);
  }

  ngOnInit() {
    this.formGroup = <UntypedFormGroup>this.controlContainer.control;
  }
}
