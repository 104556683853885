import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AssetsHelperService, DefaultApiMessage, PouchDBHelperService, SynchronizedPouchDB, createApiMessageInstance } from "angular-helpers";
import { ApisHttpClientExtended } from "apis-helpers";
import { OperatorForm } from "apis-helpers/models/operator-from-couchdb.model";
import { ReferenceDataFromCouchDB } from "apis-helpers/models/reference-data-from-couchdb.model";
import { CookieService } from "ngx-cookie-service";
import { ToastrService } from "ngx-toastr";
import { Observable, delay, firstValueFrom, forkJoin, from, lastValueFrom, map } from "rxjs";
import { HttpClientSemaeService } from "../http-client-semae.service";
import { CouchdbDatabaseService } from "./couchdb-database.service";
import { NetworkService } from "../network/network.service";

@Injectable({
  providedIn: 'root'
})
export class CouchdbService extends CouchdbDatabaseService {

  constructor(
    public http: HttpClientSemaeService,
    public pouchDbHelper: PouchDBHelperService,
    public cookieService: CookieService,
    public assetsHelperService: AssetsHelperService,
    public toastr: ToastrService) {
        super(http, pouchDbHelper, cookieService, assetsHelperService, toastr);
    }

    public loadCouchDb(): Promise<any> {
      // online = call api
      if (navigator.onLine) {
        return super.loadCouchDb();
      } else {
        this.isOnline = false;
        this.couchdbServerUrl = this.cookieService.get('CouchDBServerUrl'); 
        
        if (this.couchdbServerUrl) {
            return Promise.resolve();
        }
        else {
        return Promise.reject('CouchDBServerUrl not initialized in cookies');
        }
      }
    }

  public getOperatorFormCouchDB(): Promise<any> {
    if (this.isOnline) {
      return super.getOperatorFormCouchDB();
    } else {
      const couchDBName = this.cookieService.get('OperatorsFormDBName');
      this.operatorsFormDB = this.pouchDbHelper.getSynchronizedPouchDB(
        couchDBName,
        this.couchdbServerUrl);
      super.initOperatorsFormDB(this.couchdbServerUrl, couchDBName);
      return Promise.resolve();
    }
  }

  public getReferencesCouchDB(): Promise<any> {
    if (this.couchdbServerUrl) {
      if (this.isOnline) {
        // Get references data only if not already get
        return super.getReferencesCouchDB();
      } else {
        const couchDBName = this.cookieService.get('referencesDBName');    
        this.referencesDB = this.pouchDbHelper.getSynchronizedPouchDB(
          couchDBName,
          this.couchdbServerUrl
        );
        super.initReferencesDB(this.couchdbServerUrl, couchDBName);
        return Promise.resolve();
      }
    }
  }
}
