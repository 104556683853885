import { ConfigurationInterface } from '../interfaces';

export class Config implements ConfigurationInterface {
  production: boolean;
  baseUrl: string;
  applicationName: string;
  applicationSubtitle: string;
  authJwtApi: string;
  authJwtApiOnGateway: boolean;
  authIS4Api: string;
  authIS4ClientId: string;
  authIS4Scope: string;
  authIS4SilentRefreshTimeout: number;
  authIS4TimeoutFactor: number;
  authIS4AllowedUrls: string;
  authAzureClientId: string;
  authAzureAuthority: string;
  authAzureScope: string;
  authAzureAllowedUrls: string;
  authAzureApi: string;
  authKeycloakServer: string;
  authKeycloakRealm: string;
  authKeycloakClientId: string;
  authKeycloakResourceId: string;
}
