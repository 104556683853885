import { Component } from '@angular/core';
import { AuthentificationService } from 'angular-helpers';
import { Observable, Subscription, fromEvent, map, merge, of } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent {
  public isLoggedIn$: Observable<boolean>;
  public roles: string[] = [];

  public usedSpace: number = null;
  public usedSpaceUnit: string = '';
  public availableSpace: number = null;
  public remainingSpace: number = null;
  public percentageUsed: number = null;

  networkStatus: boolean = false;
  networkStatus$: Subscription = Subscription.EMPTY;

  constructor(private authService: AuthentificationService) {
    this.isLoggedIn$ = this.authService.isAuthenticated$;
    this.roles = this.authService.roles;
    this.getStorageInfo();
  }

  ngOnInit() {
    this.checkNetworkStatus();
  }
  ngOnDestroy(): void {
    this.networkStatus$.unsubscribe();
  }

  getStorageInfo() {
    if('storage' in navigator && 'estimate' in navigator.storage) {
      navigator.storage.estimate().then(estimate => {
        const usedBytesO = estimate.usage; // bytes to kilobytes
        const usedBytesKo = estimate.usage / 1000; // bytes to kilobytes
        const usedBytesMo = estimate.usage / 1000000; // bytes to megabytes
        const usedBytesGo = estimate.usage / 1000000000; // bytes to gigabytes

        // kilobytes less than 1 => keep in bytes
        if(Math.trunc(usedBytesKo) == 0) {
          this.usedSpace = usedBytesO;
          this.usedSpaceUnit = 'octets';
        } else {
          // kilobytes less than 1 => keep in kilobytes
          if(Math.trunc(usedBytesMo) == 0) {
            this.usedSpace = usedBytesKo;
            this.usedSpaceUnit = 'Ko';
          } else {
            // kilobytes less than 1 => keep in megabytes
            if(Math.trunc(usedBytesGo) == 0) {
              this.usedSpace = usedBytesMo;
              this.usedSpaceUnit = 'Mo';
            // else => display in gigabytes
            } else {
              this.usedSpace = usedBytesGo; // bytes to gigabytes
              this.usedSpaceUnit = 'Go';
            }
          }
        }

        this.availableSpace = estimate.quota / 1000000000; // from bytes to gigabyte
        this.remainingSpace = this.availableSpace - usedBytesGo;
        this.percentageUsed = (usedBytesGo / this.availableSpace) * 100;
      });
    }
  }

  public login() {
    this.authService.login();
  }

  public logout() {
    this.authService.logout();
  }

  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        console.log('status', status);
        this.networkStatus = status;
      });
  }
}
