import { Component } from '@angular/core';

@Component({
  selector: 'app-loader',
  template: `
    <div id="spinner">
      <mat-spinner class="mat-spinner-color"></mat-spinner>
    </div>
  `,
  styles: [`
    #spinner {
      z-index: 10;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 5em;
      display: flex;
      background: rgba(255, 255, 255, 0.8);
      align-items: center;
      justify-content: center;
    }
    .mat-spinner-color::ng-deep circle{
      stroke: #686868 !important;
    }
  `]
})
export class LoaderComponent {
}
