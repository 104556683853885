import { Component, AfterViewInit, Input, Output, EventEmitter  } from '@angular/core';
import * as L from 'leaflet';

@Component({
  selector: 'app-leaflet-map',
  templateUrl: './leaflet-map.component.html',
  styleUrls: ['./leaflet-map.component.scss']
})
export class LeafletMapComponent implements AfterViewInit  {
  @Input() initialPosition: L.LatLng;
  @Output() newPosition = new EventEmitter<L.LatLng>();
  private map;
  private marker;

  constructor() { }

  private initMap(): void {
    // Create map
    this.map = L.map('map', {
      zoom : 16,
      center : this.initialPosition
    });

    // Add map layer
    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3
    });
    tiles.addTo(this.map);

    // Marker
    const pinIcon = L.icon({
      iconUrl: 'assets/icons/svg/pin-gps-for-map.svg',
      iconSize: [38, 95]
    });
    this.marker = L.marker(
      [this.initialPosition.lat, this.initialPosition.lng],
      {
        draggable: true,
        icon: pinIcon,
        alt: 'Icône positionnement GPS'
      }
    )
    this.marker.on('dragend', (e: L.DragEndEvent) => { this.newPosition.emit(e.target._latlng)})
    this.marker.addTo(this.map);
  }

  ngAfterViewInit(): void {
    this.initMap();
  }

  // Send new position of marker on map
  syncMapPosition() {
    this.newPosition.emit(
      this.marker.getLatLng()
    );
  }
}
