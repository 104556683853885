// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-container {
  position: absolute;
  top: 19rem;
  left: 0;
  right: 0;
  bottom: 4em;
  margin: 30px;
}

.map-frame {
  border: 2px solid black;
  height: 100%;
}

#map {
  height: 100%;
}

#arrow-icon {
  stroke: white;
  color: white;
  margin-right: 0;
  width: 1.5em;
  height: 1.5em;
}`, "",{"version":3,"sources":["webpack://./projects/Apps/Semae/src/app/components/leaflet-map/leaflet-map.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,UAAA;EACA,OAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;AACF;;AAEA;EACE,uBAAA;EACA,YAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,aAAA;EACA,YAAA;EACA,eAAA;EACA,YAAA;EACA,aAAA;AACF","sourcesContent":[".map-container {\r\n  position: absolute;\r\n  top: 19rem;\r\n  left: 0;\r\n  right: 0;\r\n  bottom: 4em;\r\n  margin: 30px;\r\n}\r\n\r\n.map-frame {\r\n  border: 2px solid black;\r\n  height: 100%;\r\n}\r\n\r\n#map {\r\n  height: 100%;\r\n}\r\n\r\n#arrow-icon {\r\n  stroke: white;\r\n  color: white;\r\n  margin-right: 0;\r\n  width: 1.5em;\r\n  height: 1.5em;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
