// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page {
  height: calc(100% - 7rem);
  padding: 2em;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  align-items: center;
  overflow-x: hidden;
}
.page .content {
  height: 100%;
  max-width: 40em;
  width: -webkit-fill-available;
  width: -moz-available;
  overflow-x: hidden;
  overflow-y: auto;
}

.title-container {
  width: -webkit-fill-available;
  width: -moz-available;
  max-width: 40em;
}
.title-container h1 {
  margin-top: 0 !important;
}`, "",{"version":3,"sources":["webpack://./projects/Apps/Semae/src/app/components/page/page.component.scss"],"names":[],"mappings":"AAEA;EACE,yBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;AADF;AAGE;EACE,YAAA;EACA,eAAA;EACA,6BAAA;EAEA,qBAAA;EACA,kBAAA;EACA,gBAAA;AAFJ;;AAKA;EACE,6BAAA;EAEA,qBAAA;EACA,eAAA;AAHF;AAKE;EACE,wBAAA;AAHJ","sourcesContent":["@import \"projects/Apps/Semae/src/app/styles/variables.scss\";\r\n\r\n.page {\r\n  height: calc(100% - 7rem); // All height minus bottom nav\r\n  padding: 2em;\r\n  display: flex;\r\n  flex-direction: column;\r\n  overflow-y: auto;\r\n  align-items: center;\r\n  overflow-x: hidden;\r\n\r\n  .content {\r\n    height: 100%;\r\n    max-width: 40em;\r\n    width: -webkit-fill-available;\r\n    //For Firefox\r\n    width: -moz-available;\r\n    overflow-x: hidden;\r\n    overflow-y: auto;\r\n  }\r\n}\r\n.title-container {\r\n  width: -webkit-fill-available;\r\n  //For Firefox\r\n  width: -moz-available;\r\n  max-width: 40em;\r\n\r\n  h1 {\r\n    margin-top: 0!important;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
