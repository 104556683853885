import { Component, OnInit } from '@angular/core';
import { GeolocalisationService } from '../../services/geolocalisation/geolocalisation.service';
import * as L from 'leaflet';

@Component({
  selector: 'app-gps-page',
  templateUrl: './gps-page.component.html'
})
export class GpsPageComponent implements OnInit {
  public position: GeolocationPosition;
  public errorMessage: string = null;
  public newPosition: L.LatLng;

  constructor(private geolocationService: GeolocalisationService) {
  }

  ngOnInit(): void {
    this.geolocationService.getCurrentPosition(
      (position: GeolocationPosition) => {
        this.position = position;
        this.errorMessage = null;
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED)
        {
          this.errorMessage = "Veuillez autoriser l'accès à la géolocalisation.";
        } else if (error.code === error.POSITION_UNAVAILABLE) {
          this.errorMessage = "La récupération de la géolocalisation a renvoyé une erreur interne.";
        } else if (error.code === error.TIMEOUT) {
          this.errorMessage = "Le temps imparti pour récupérer la géolocalisation a été dépassé.";
        } else {
          this.errorMessage = "Une erreur est survenue lors de la récupération de la géolocalisation.";
        }
      }
    );
  }

  requestPermission() {
    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      if (result.state === "granted") {
        alert('granted ' + result.state);

      } else if (result.state === "prompt") {
        alert('prompt ' + result.state);

      } else if (result.state === "denied") {
        alert('denied ' + result.state);

      }
      result.addEventListener("change", () => {
        alert('change ' + result.state);
      });
    });
  }

  public get latitude(): number {
    return this.newPosition !== undefined
      ? this.newPosition.lat
      : this.position?.coords?.latitude
  }

  public get longitude(): number {
    return this.newPosition !== undefined
      ? this.newPosition.lng
      : this.position?.coords?.longitude
  }

  public get positionToLeaflet(): L.LatLng {
    return L.latLng(this.position?.coords.latitude, this.position?.coords.longitude);
  }

  public setNewPosition(newPosition: L.LatLng) {
    this.newPosition = newPosition;
  }
}
