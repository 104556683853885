import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { AuthIS4GuardWithForcedLogin } from './guards/auth-is4-guard-with-forced-login.service';
import { AuthIS4Guard } from './guards/auth-is4-guard.service';
import { AuthIS4Service } from './services/auth-is4.service';

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  imports: [
    HttpClientModule,
    OAuthModule.forRoot(),
  ],
  providers: [
    { provide: 'IAuthIS4Service', useClass: AuthIS4Service },
    AuthIS4Service,
    AuthIS4Guard,
    AuthIS4GuardWithForcedLogin,
  ],
})
export class AuthIS4Module {
  static forRoot(): ModuleWithProviders<AuthIS4Module> {
    return {
      ngModule: AuthIS4Module,
      providers: [
        // { provide: AuthConfig, useFactory: resolveAuthIS4Config },
        // { provide: OAuthModuleConfig, useValue: authIS4ModuleConfig },
        { provide: OAuthStorage, useFactory: storageFactory },
      ]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: AuthIS4Module) {
    if (parentModule) {
      throw new Error('AuthIS4Module is already loaded.');
    }
  }
}
