import { HttpClient, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationInterface } from '../interfaces/configuration.interface';

@Injectable({ providedIn: 'root' })
export class ConfigService {
  static configUrl = 'configs/config.json';
  static configEnvUrl = 'configs/config.env.json';
  static params: ConfigurationInterface = undefined;
  public params: ConfigurationInterface;
  public paramsPromise: Promise<ConfigurationInterface>;
  private http: HttpClient;

  static loadConfig(callback: (config: ConfigurationInterface) => void): Promise<void> {
    const p = Promise.all([
      fetch(this.configUrl),
      fetch(this.configEnvUrl)
    ]).then(async ([response1, response2]) => {
      await Promise.all([response1.json(), response2.json()]).then(([config, configEnv]) => {
        console.log('Load config to global configuration');
        ConfigService.params = Object.assign({}, config, configEnv);
        callback(ConfigService.params);
      });
    });
    return p;
  }

  constructor(handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }

  public loadConfig(): Promise<ConfigurationInterface> {
    if (ConfigService.params) {
      console.log('Get config from global configuration');
      this.params = ConfigService.params;
      return undefined;
    } else {
      const p1 = this.http.get<ConfigurationInterface>(ConfigService.configUrl).toPromise();
      const p2 = this.http.get<ConfigurationInterface>(ConfigService.configEnvUrl).toPromise();
      this.paramsPromise = Promise.all([p1, p2]).then((values) => {
        const config = values[0];
        const configEnv = values[1];
        ConfigService.params = Object.assign({}, config, configEnv);
        this.params = ConfigService.params;
        return ConfigService.params;
      });
      return this.paramsPromise;
    }
  }
}
