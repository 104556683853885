import { OAuthModuleConfig } from 'angular-oauth2-oidc';
import { ConfigurationProvider } from '../config';

// export const authIS4ModuleConfig: OAuthModuleConfig = {
//   resourceServer: {
//     sendAccessToken: true,
//   }
// };

export function resolveAuthIS4ModuleConfig(configService: ConfigurationProvider): OAuthModuleConfig {
  if (configService.params.authIS4Api) {
    const allowedUrls: Array<string> = (configService.params ?
      configService.params.authIS4AllowedUrls.split(',') : undefined);
    const moduleConfig: OAuthModuleConfig = {
      resourceServer: {
        allowedUrls: (configService.params ? allowedUrls : undefined),
        sendAccessToken: true,
      }
    };
    return moduleConfig;
  } else
  {
    return undefined;
  }
}
