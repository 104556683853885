import { NgModule } from '@angular/core';
import { DirectivesModule } from 'angular-helpers';

@NgModule({
  imports: [
    DirectivesModule
  ],
  exports: [
    DirectivesModule
  ]
})
export class ApisDirectivesModule {
}
