import { Constructor } from "../helpers/constructor";
import { createApiMessageInstance } from "./api-message.model";
import { EntityWithKey } from "./entity-with-key.model";

export class RealtimeEntitiesEvent<T extends EntityWithKey<TKey>, TKey extends number | string> { 
  public eventType: string;
  public obj: T;

  constructor(c: Constructor<T>, json: any) {
    if (json) {
      this.eventType = json['eventType'];
      this.obj = createApiMessageInstance(c).loadFromJson(json['obj']);
    }
  }
}
