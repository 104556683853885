import { Component, Input } from '@angular/core';
import { Unsubscriber } from '../../services/unsubscriber/unsubscriber.service';
import { MatDialog } from '@angular/material/dialog';
import { OperatorDialogComponent, OperatorDialogModel } from '../../dialogs/operator-dialog/operator-dialog.component';
import { OperatorForm } from 'apis-helpers/models/operator-from-couchdb.model';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { on } from 'events';
import { CouchdbService } from '../../services/couchdb-database/couchdb.service';

@Component({
  selector: 'app-list-operators',
  templateUrl: './list-operators-page.component.html',
  styleUrls: ['./list-operators-page.component.scss']
})
export class ListOperatorsPageComponent extends Unsubscriber {
  @Input() internetConnectionError = false;
  @Input() loading = false;

  constructor(
    private dialog: MatDialog,
    public couchdbService: CouchdbService
    ) {
    super();
  }

  private openOperatorDialog(operator?: OperatorForm) {
    this.dialog.open(OperatorDialogComponent, {
      data: new OperatorDialogModel(operator),
      autoFocus: false,
      panelClass: 'dialog-operator'
    });
  }

  //#region operators
  public addOperator() {
    this.openOperatorDialog();
  }
  public updateOperator(operator: OperatorForm) {
    this.openOperatorDialog(operator);
  }
  public deleteOperator(operator: OperatorForm) {
    let dialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Suppression',
        message:  `Êtes-vous sûr de vouloir supprimer cette visite ?`,
      }
    });

    this.addSubscription(
      dialog.afterClosed().subscribe((result) => {
        if (result) {
          this.couchdbService.deleteOperatorForm(operator);
        }
      })
    );
  }
  //#endregion operators
}
