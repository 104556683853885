import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { AuthIS4Service } from '../services/auth-is4.service';

@Injectable()
export class AuthIS4GuardWithForcedLogin {
  private isAuthenticated: boolean;

  constructor(
    private authIS4Service: AuthIS4Service,
    private logger: NGXLogger,
  ) {
    this.authIS4Service.isAuthenticated$.subscribe(i => this.isAuthenticated = i);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {

    if (localStorage.getItem('loggedin')) {
      // TODO: call api
      this.logger.debug('loggedin value', localStorage.getItem('loggedin'));
      localStorage.setItem('loggedin', 'false');
    }

    return this.authIS4Service.isDoneLoading$
      .pipe(filter(isDone => isDone))
      .pipe(tap(() => this.isAuthenticated || this.authIS4Service.login(state.url)))
      .pipe(map(() => this.isAuthenticated));
  }
}
