import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ColorHelperService {
  constructor() {}

  // convertHexaCodeToRgba(hexaCode: string, alpha: number = 100): string {
  convertHexaCodeToDarkerRgb(hexaCode: string, divider: number = 1.3): string {
    if (hexaCode) {
      const transformedArray: string[] = hexaCode.split('');
      transformedArray.shift();

      const colorsArray: string[] = [];
      for (let i = 0; i < transformedArray.length; i += 2) {
        const colorHexaString: string =
          transformedArray[i] + transformedArray[i + 1];
        // const colorNumber: number = parseInt(colorHexaString, 16);
        let colorNumber: number = parseInt(colorHexaString, 16);

        colorNumber = Math.round(colorNumber / divider);

        colorsArray.push(colorNumber.toString());
      }

      // return `rgba(${colorsArray[0]},${colorsArray[1]},${colorsArray[2]},${alpha}%)`;
      return `rgb(${colorsArray[0]},${colorsArray[1]},${colorsArray[2]})`;
    }
  }
}
