import { Directive, ElementRef, HostListener } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { StringHelper } from "../helpers/string.helper";

/** 
 * Send log on click event on element and children, use value on parameter or get id + innerText 
 * Add attribute with value 'off' to disable log on child
*/
@Directive({
  selector: '[frmkLogClick]',
})
export class LogClickDirective {

  constructor(private elementRef: ElementRef, private logger: NGXLogger) {
  }
  
  @HostListener('click', ['$event']) 
  onClick(e) {
    if (!e.preventDirective) {
      const attributeValue: string = this.getAttribute(e.target);
      if (attributeValue !== 'off' ) {
        let value = attributeValue;
        if (!value && e.target) {
          value = e.target.innerText;
          if (!value) {
            value = e.target.getAttribute("alt");
          }
          if (!value) {
            value = e.target.getAttribute("ng-reflect-message");
          }
          if (!value) {
            value = e.target.getAttribute("aria-label");
          }
          if (!value) {
            if (e.target instanceof SVGElement) {
              value = e.target.parentElement?.getAttribute("svgicon");
            }
          }
          if (!value) {
            value = e.target.getAttribute("class");
          }
          value = StringHelper.truncate(value, 25);
        }

        if (e.target?.id) {
          value = e.target?.id + ' - ' + value;
        }
        this.logger.info(`Click on '${value}'`);
      }
      e.preventDirective = true;
    }
  }

  private getAttribute(target: any) {
    let attributeValue: string = target.getAttribute("frmkLogClick");
    while (attributeValue === null && target.parentElement !== null) {
      target = target.parentElement;
      attributeValue = target.getAttribute("frmkLogClick");
    }
    return attributeValue;
  }
}