import { Component, Input, OnInit } from "@angular/core";
import { ControlContainer, UntypedFormGroup } from "@angular/forms";

@Component({
  selector: "frmk-date-range-field",
  templateUrl: "./date-range-field.component.html",
  styleUrls: ["./date-range-field.component.scss"],
})
export class DateRangeFieldComponent implements OnInit {
  formGroup: UntypedFormGroup;
  @Input() label: string;
  @Input() controlNameFrom: string;
  @Input() controlNameTo: string;

  constructor(private controlContainer: ControlContainer) {}
  
  ngOnInit() {
    this.formGroup = <UntypedFormGroup>this.controlContainer.control;
  }
}
