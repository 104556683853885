
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import HttpStatusCode from '../enums/http-status-code.enum';

@Injectable()
export class UncatchedErrorHandler implements ErrorHandler {
  constructor(private logger: NGXLogger) { }
  handleError(error) {
    if (error instanceof HttpErrorResponse) {
      this.globalHttpHandleError(<HttpErrorResponse> error);
    } else if (error instanceof TypeError) {
      this.logger.error('Uncatched type error: ' + error.message, error.stack);
    } else if (error instanceof Error) {
      const rejection = error['rejection'];
      if (rejection instanceof HttpErrorResponse) {
        this.globalHttpHandleError(<HttpErrorResponse> rejection);
      } else {
        this.logger.error('Uncatched error: ' + error.message, error.stack);
      }
    } else {
      this.logger.error('Uncatched error: ' + error);
    }
  }

  private globalHttpHandleError(error: HttpErrorResponse) {
    if (error.status === HttpStatusCode.PRECONDITION_FAILED) {
      this.logger.debug('Uncatched missing parameter: ' + error.message, 'Status code:', (<HttpErrorResponse> error).status);
    } else {
      this.logger.error('Uncatched http error: ' + error.message, 'Status code:', (<HttpErrorResponse> error).status);
    }
  }
}
