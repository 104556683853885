// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  text-align: center;
  display: block;
  margin-top: 10%;
  padding: 10px;
}

form {
  max-width: 300px;
  margin: auto;
}

mat-form-field {
  width: 100%;
}

.actions {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./projects/Libs/angular-helpers/src/lib/auth-jwt/components/login-jwt/login-jwt.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,cAAA;EACA,eAAA;EACA,aAAA;AACF;;AAEA;EACE,gBAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":[":host {\r\n  text-align: center;\r\n  display: block;\r\n  margin-top: 10%;\r\n  padding: 10px;\r\n}\r\n\r\nform {\r\n  max-width: 300px;\r\n  margin: auto;\r\n}\r\n\r\nmat-form-field {\r\n  width: 100%;\r\n}\r\n\r\n.actions {\r\n  text-align: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
