import { NgModule } from '@angular/core';
import { IntegerOnlyDirective } from '../directives/integer-only.directive';
import { LogClickDirective } from '../directives/log-click.directive';
import { TwoDigitDecimalDirective } from '../directives/two-digit-decimal.directive';
import { ComponentHostDirective } from '../directives/component-host.directive';

@NgModule({
  declarations: [
    LogClickDirective,
    IntegerOnlyDirective,
    TwoDigitDecimalDirective,
    ComponentHostDirective
  ],
  exports: [
    LogClickDirective,
    IntegerOnlyDirective,
    TwoDigitDecimalDirective,
    ComponentHostDirective
  ]
})
export class DirectivesModule {
}
