import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApisHttpClientExtended } from '../apis-http-client-extended/apis-http-client-extended.service';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(protected http: ApisHttpClientExtended) { }

  public uploadFiles(files: File[], params?: any): Observable<any> {
    return this.http.postMultiPartsWithFilesToExampleApi('upload/UploadFiles', files, params);
  }
}
