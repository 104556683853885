import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthJwtService } from '../auth-jwt/services/auth-jwt.service';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class HttpErrorsInterceptor implements HttpInterceptor {

  constructor(private authJwtService: AuthJwtService,
    private logger: NGXLogger,  
    private toastr: ToastrService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((err: any) => {
      if (err?.error?.typeMessage === 'JsonErrorResponse' && err.error.messages) {
        const errorMessage = err.error.messages.map(m => m).join(",");
        this.toastr.error(errorMessage);
      } else if (!(err?.status === 401) && err?.url !== this.authJwtService?.getRefreshUrl()) {
        this.logger.warn(`Unable to connect to the Server`);
      }      
      return throwError(err);
    }));
  }
}
