import { Observable } from 'rxjs';
import { EntityWithKey } from '../../models';
import { NGXLogger } from 'ngx-logger';
import { HttpClientExtended } from '../http-extended/http-client-extended.service';
import { HubsManagmentService } from '../hubs-managment/hubs-managment.service';
import { GetterRelation1N } from '../getter-relation-1n/getter-relation-1n.service';
import { UpdatableGetterInterface } from '../../interfaces/updatable-getter.interface';
import { Constructor } from '../../helpers/constructor';

export abstract class CrudRelation1N<TEntity extends EntityWithKey<TEKey>, 
                                     TEKey extends number | string,
                                     TRelation extends EntityWithKey<TRKey>,
                                     TRKey extends number | string> 
                extends GetterRelation1N<TEntity, TEKey, TRelation, TRKey>{

  constructor(protected http: HttpClientExtended, protected logger: NGXLogger, 
              protected updatableServiceOfEntity: UpdatableGetterInterface<TEntity, TEKey>, protected hubsManagment: HubsManagmentService,
              protected controllerBaseUrl: string, protected constructorType: Constructor<TRelation>) { 
    super(http, logger, updatableServiceOfEntity, hubsManagment, controllerBaseUrl, constructorType);
  }

  // #region CRUD
  // #region Create
  public createSingleData(relation: TRelation): Observable<TRelation> {
    const referenceKey = this.getReferenceKey(relation);
    this.logger.info(`Create data in ${this.controllerBaseUrl} with referenceKey: ${referenceKey}`);
    return this.http.postData(this.constructorType, this.getUrlFromControllerWithReference(referenceKey), relation).pipe(
      this.refreshRelationFromPipe()
    );
  }
  // #endregion
  
  // #region Update
  public updateSingleData(relation: TRelation): Observable<TRelation> {
    const referenceKey = this.getReferenceKey(relation);
    this.logger.info(`Update data in ${this.controllerBaseUrl} with reference key ${referenceKey} and key ${relation.GetKey()}`);
    return this.http.postData(
      this.constructorType, 
      this.getUrlFromControllerWithReference(referenceKey, relation.GetKey().toString()), relation)
        .pipe(this.refreshRelationFromPipe());
  }
  // #endregion

  // #region Delete
  public deleteSingleData(referenceKey: TEKey, relationKey: TRKey): Observable<TRelation> {
    this.logger.info(`Delete data in ${this.controllerBaseUrl} with referenceKey: ${referenceKey} and key ${relationKey}`);
    return this.http.deleteData(
      this.constructorType, 
      this.getUrlFromControllerWithReference(referenceKey, relationKey.toString()))
        .pipe(this.refreshDeletedRelationFromPipe());
  }
  // #endregion
  // #endregion CRUD
}
