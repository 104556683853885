import { Injectable } from '@angular/core';
import { ApisHttpClientExtended } from 'apis-helpers';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpClientSemaeService extends ApisHttpClientExtended {
  
  public postToOperatorsControlsApi(path: string, data?: any): Observable<object> {
    return this.post(this.getUrl(this.config.params.operatorsControlsApi, path), data);
  }

  public getFromOperatorsControlsApi(path: string, data?: any): Observable<object> {
    return this.get(this.getUrl(this.config.params.operatorsControlsApi, path), data);
  }
}
