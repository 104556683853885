import { Injectable } from '@angular/core';
import { HubConnection } from '@microsoft/signalr';
import { ApiMessage, HttpClientExtended, HubsManagmentService, Constructor, AuthentificationService } from 'angular-helpers';
import { ApisConfigurationProvider } from '../../config/apis-config-provider';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})
export class ApisHubsManagmentService extends HubsManagmentService {

  constructor(http: HttpClientExtended, 
    toastr: ToastrService, 
    authentificationService: AuthentificationService,
    logger: NGXLogger, 
    protected config: ApisConfigurationProvider) { 
    super(http, toastr, authentificationService, logger);
  }

  public connectHubFromApi(api: string, path: string, withAuth = false): HubConnection {
    return super.connectHub(this.config.getApiHubUrl(api, path), withAuth);
  }

  public getFromControllerAndHub<T extends ApiMessage>(c: Constructor<T>,
                                hub: HubConnection,
                                hubMethodName: string,
                                controllerApi: string, controllerPath: string, controllerOptions?: any): Observable<T> {
    return super.getFromControllerAndHub(c, hub, hubMethodName, this.config.getApiHubUrl(controllerApi, controllerPath));
  }



  public connectHubFromMessagingApi(path: string, withAuth = false): HubConnection {
    return this.connectHubFromApi(this.config.params.messagingApi, path, withAuth);
  }

  public connectHubFromExampleApi(path: string, withAuth = false): HubConnection {
    return this.connectHubFromApi(this.config.params.exampleApi, path, withAuth);
  }
}
