import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { GeolocalisationService } from '../services/geolocalisation/geolocalisation.service';
import { HttpClientSemaeService } from '../services/http-client-semae.service';
import { CouchdbService } from '../services/couchdb-database/couchdb.service';
import { NetworkService } from '../services/network/network.service';

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule
  ],
  providers: [
    HttpClientModule,
    HttpClientSemaeService,
    GeolocalisationService,
    CouchdbService, 
    NetworkService
  ]
})
export class ServicesModule { }
