// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio-button {
  min-width: 4.5em;
  min-height: 5.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #c9c9c9;
  border-radius: 0 10px 0 10px;
  margin: 0 0.1em 1em 0.1em;
  padding: 0.5em 0.1em;
  cursor: pointer;
}

.acronym-radio {
  font-size: 32px;
  font-weight: bold;
}

.name-radio {
  display: flex;
  padding: 0 0.2em;
  line-height: 15px;
  margin-top: 0.8em;
}
.name-radio span {
  font-size: 11px;
  text-align: center;
}

.statusSelected {
  color: white;
  background-color: #686868;
}

@media only screen and (min-width: 400px) {
  .radio-button {
    min-width: 5.5em;
  }
}`, "",{"version":3,"sources":["webpack://./projects/Apps/Semae/src/app/components/radio-button/radio-button.component.scss","webpack://./projects/Apps/Semae/src/app/styles/variables.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,yBCDgB;EDEhB,4BAAA;EACA,yBAAA;EACA,oBAAA;EACA,eAAA;AADF;;AAGA;EACE,eAAA;EACA,iBAAA;AAAF;;AAEA;EACE,aAAA;EACA,gBAAA;EACA,iBAAA;EACA,iBAAA;AACF;AACE;EACE,eAAA;EACA,kBAAA;AACJ;;AAGA;EACE,YAAA;EACA,yBC1BW;AD0Bb;;AAGA;EACE;IACE,gBAAA;EAAF;AACF","sourcesContent":["@import \"../../styles/variables.scss\";\r\n\r\n.radio-button {\r\n  min-width: 4.5em;\r\n  min-height: 5.5em;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  background-color: $secondary-color;\r\n  border-radius: 0 10px 0 10px;\r\n  margin: 0 0.1em 1em 0.1em;\r\n  padding: 0.5em 0.1em;\r\n  cursor: pointer;\r\n}\r\n.acronym-radio {\r\n  font-size: 32px;\r\n  font-weight: bold;\r\n}\r\n.name-radio {\r\n  display: flex;\r\n  padding: 0 0.2em;\r\n  line-height: 15px;\r\n  margin-top: 0.8em;\r\n\r\n  span {\r\n    font-size: 11px;\r\n    text-align: center;\r\n  }\r\n}\r\n\r\n.statusSelected {\r\n  color: white;\r\n  background-color: $main-color;\r\n}\r\n\r\n@media only screen and (min-width: 400px) {\r\n  .radio-button {\r\n    min-width: 5.5em;\r\n  }\r\n}\r\n","$font-family-base: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\r\n$font-size-base: 14px;\r\n$line-height-base: 1.42857143;\r\n$text-color: #333;\r\n$body-bg: #fff;\r\n$bg-color: white;\r\n$main-color: #686868;\r\n$secondary-color: #c9c9c9;\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
