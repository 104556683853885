import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import PouchDB from 'pouchdb';
import PouchDBFind from 'pouchdb-find';
import { SynchronizedPouchDB } from "../../models/synchronized-pouchdb.model";
import { AuthentificationService } from "../authentification/authentification.service";


@Injectable({
  providedIn: 'root'
})
export class PouchDBHelperService {

  constructor(protected logger: NGXLogger,
    private authentificationService: AuthentificationService) {
    PouchDB.plugin(PouchDBFind);
  }

  public getSynchronizedPouchDB(dbName: string, remoteUrl: string, remoteDbLogin?: string, remoteDbPwd?: string): SynchronizedPouchDB {
    this.logger.info(`Get synchronized database ${dbName} from ${remoteUrl}`);
    let synchronizedPouchDB = new SynchronizedPouchDB(
      this.createLocalDatabase(dbName), 
      this.createRemoteDatabase(remoteUrl + dbName, remoteDbLogin, remoteDbPwd), 
      () => this.getSession(remoteUrl, remoteDbLogin, remoteDbPwd).then(s => s.userCtx), 
      this.logger
    );
    synchronizedPouchDB.localDB.sync(synchronizedPouchDB.remoteDB, {live: true, retry: true});
    
    return synchronizedPouchDB;
  }

  private createLocalDatabase(dbName: string) {
    return new PouchDB(dbName, { auto_compaction: true });
  }

  private createRemoteDatabase(dbName: string, remoteDbLogin?: string, remoteDbPwd?: string) {
    const _this = this;
    return new PouchDB(dbName, {
      auto_compaction: true,
      fetch: function (url, options) {
        const authorization = _this.getAuthorization(remoteDbLogin, remoteDbPwd);
        options.headers.set('Authorization', authorization);
        return PouchDB.fetch(url, options);
      }
    });
  }

  private async getSession(couchDBServerUrl: string, remoteDbLogin?: string, remoteDbPwd?: string): Promise<any> {
    const authorization = this.getAuthorization(remoteDbLogin, remoteDbPwd);
    return await fetch(couchDBServerUrl + '_session', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization' : authorization
      }
    }).then(res => res.json());
  }

  private getAuthorization(remoteDbLogin?: string, remoteDbPwd?: string) {
    if (this.authentificationService?.hasBearerToken) {
      return `Bearer ${this.authentificationService.currentToken}`;
    } else {
      let str = remoteDbLogin + ':' + remoteDbPwd;
      let token = btoa(unescape(encodeURIComponent(str)));
      return 'Basic ' + token;
    }
  }
}
