import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthJwtService } from '../services/auth-jwt.service';

@Injectable({
  providedIn: 'root'
})
export class LoginJwtGuard {

  constructor(private authJwtService: AuthJwtService,
    private router: Router, private route: ActivatedRoute) { }

  async canActivate() {
    if (this.authJwtService.hasToken()) {
      const redirectTo = this.route.snapshot.queryParamMap.get('redirectTo');
      if (redirectTo) {
        await this.router.navigate([redirectTo]);
      } else {
        await this.router.navigate(['/nav/home']);
      }
    }
    return !this.authJwtService.hasToken();
  }
}
