import { BehaviorSubject, Observable } from "rxjs";
import { switchMap } from "rxjs/operators";

export class SubjectWithResult<T> {
  public constructor(public sub$: BehaviorSubject<T>, public result: T) {
    
  }
}
export function createSubjectWithResult<T>(sendResult: (result: SubjectWithResult<T>) => void) {
  return (source$: Observable<T>) => {
    return source$.pipe(
      switchMap((data: T) => {
        // To allow to refresh observable, create subject on it
        const sub = new BehaviorSubject<T>(null);
        sub.next(data);

        const objWithResult = new SubjectWithResult<T>(sub, data);
        sendResult(objWithResult);

        return sub;
      })
    );
  };
}