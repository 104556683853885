import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AuthAzureService } from './services/auth-azure.service';
import { DirectivesModule } from '../modules/directives.modules';

@NgModule({
    declarations: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        AuthAzureService,
    ],
    imports: [
        CommonModule,
        RouterModule,
        HttpClientModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        DirectivesModule,
    ],
    exports: [
        ReactiveFormsModule
    ]
})
export class AuthAzureModule { }
