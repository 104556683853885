import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ControlContainer, UntypedFormGroup } from "@angular/forms";
import { Moment } from "moment";

@Component({
  selector: "frmk-date-field",
  templateUrl: "./date-field.component.html",
  styleUrls: ["./date-field.component.scss"],
})
export class DateFieldComponent implements OnInit {
  formGroup: UntypedFormGroup;
  @Input() label: string;
  @Input() controlName: string;
  @Output() dateChanged = new EventEmitter<Moment>();

  constructor(private controlContainer: ControlContainer) {}

  get control() {
    return this.formGroup.get(this.controlName);
  }

  ngOnInit() {
    this.formGroup = <UntypedFormGroup>this.controlContainer.control;
  }

  public onDateChange(event) : void {
    this.dateChanged.emit(event.value);
  }
}
