import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, EMPTY } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { ConfigurationProvider } from '../config';
import { AuthJwtService } from './services/auth-jwt.service';
import { LoginJwtDialogComponent } from './dialogs/login-jwt-dialog/login-jwt-dialog.component';

@Injectable()
export class JwtTokenInterceptor implements HttpInterceptor {
  ignoreRoutes: string[];

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(protected http: HttpClient, private configService: ConfigurationProvider, 
    private authJwtService: AuthJwtService) {
    const suffix = (this.configService.params.authJwtApiOnGateway) ? '' : '/api';
    this.ignoreRoutes = [
      this.configService.params.authJwtApi + suffix + '/authenticate/login',
      this.configService.params.authJwtApi + suffix + '/authenticate/refresh'
    ];
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.configService.params.authJwtApi) {
      // Do not add authentification on request in ignoreRoutes, ignore interception
      if (this.ignoreRoutes.filter(route => request.url.search(route) >= 0).length > 0) {
        return next.handle(request);
      }

      // Add token in request
      if (this.authJwtService.getJwtToken()) {
        request = this.addToken(request, this.authJwtService.getJwtToken());
      }

      return next.handle(request).pipe(catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next);
        } else {
          return throwError(error);
        }
      }));
    } else {
      return next.handle(request);
    }
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  private switchToLoginDialog(request: HttpRequest<any>, next: HttpHandler) {
    return this.authJwtService.openLoginDialog(LoginJwtDialogComponent).pipe(
      switchMap(() => {
        this.isRefreshing = false;
        if (this.authJwtService.hasToken()) {
          return next.handle(this.addToken(request, this.authJwtService.getJwtToken()));
        } else {
          return EMPTY;
        }
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      if (this.authJwtService.hasToken()) {
        return this.authJwtService.refreshToken().pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;
            this.refreshTokenSubject.next(token.token);
            return next.handle(this.addToken(request, token.token));
          }),
          catchError(error => {
            if (error.status === 404) { // Not Found
              return this.switchToLoginDialog(request, next);
            } else {
              return throwError(error);
            }
          }));
      } else {
        return this.switchToLoginDialog(request, next);
      }
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }
}
