import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, IHttpConnectionOptions, ILogger, LogLevel } from '@microsoft/signalr';
import { NGXLogger } from 'ngx-logger';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { Observable, Subscriber, firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constructor } from '../../helpers/constructor';
import { ApiMessage, createApiMessageInstance } from '../../models';
import { AuthentificationService } from '../authentification/authentification.service';
import { HttpClientExtended } from '../http-extended/http-client-extended.service';

class SignalrLogger implements ILogger {
  constructor(private logger: NGXLogger) {
  }
  public log(logLevel: LogLevel, message: string): void {
    switch (logLevel) {
      case LogLevel.Trace:
        this.logger.trace(message);
        break;
      case LogLevel.Debug:
        this.logger.debug(message);
        break;
      case LogLevel.Information:
        this.logger.info(message);
        break;
      case LogLevel.Warning:
        this.logger.warn(message);
        break;
      case LogLevel.Error:
        this.logger.error(message);
        break;
      case LogLevel.Critical:
        this.logger.fatal(message);
        break;
      }
  }
}

@Injectable({
  providedIn: 'root'
})
export class HubsManagmentService {
  private connectedHubs = new Map<string, HubConnection>();
  static reconnectionsUrls: string[] = [];
  static toastMessageByUrlOnReconnection: boolean = false;
  static globalToastMessageOnReconnection: boolean = true;
  static globalToastMessage: ActiveToast<any>;

  constructor(
    protected http: HttpClientExtended,
    protected toastr: ToastrService,
    protected authentificationService: AuthentificationService,
    private logger: NGXLogger) { }

  public connectHub(url: string, withAuth = false): HubConnection {
    let hub: HubConnection;
    const signalrLogger = new SignalrLogger(this.logger);    
    hub = this.connectedHubs.get(url);    
    if (!hub) {
      let options: IHttpConnectionOptions = undefined;
      if (withAuth) {
        if (this.authentificationService.jwtIsConfiguredOnServer || this.authentificationService.keycloakIsConfiguredOnServer) {
          options = {
            accessTokenFactory: async () => {
              if (this.authentificationService.hasBearerToken) {
                if (this.authentificationService.isTokenExpired) {
                  this.logger.info('Refresh token to get accessTokenFactory');
                  await this.authentificationService.refreshTokenPromise;
                }
                return this.authentificationService.currentToken;
              }
            }
          };
        } else if (this.authentificationService.is4IsConfiguredOnServer) {
          options = {
            accessTokenFactory: async () => {
              return this.authentificationService.currentToken;
            }
          };
        }
      }
        
      hub = new HubConnectionBuilder()
        .configureLogging(signalrLogger)
        .withUrl(url, options)
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: retryContext => {
            let nextRetry = null;
            if (retryContext.elapsedMilliseconds < 5000) {
              nextRetry = 3000;
            } else if (retryContext.elapsedMilliseconds < 600000) {               
              nextRetry = 10000;
            }
            
            if (HubsManagmentService.reconnectionsUrls.indexOf(url) == -1) {
              HubsManagmentService.reconnectionsUrls.push(url);
              if (HubsManagmentService.globalToastMessageOnReconnection && !HubsManagmentService.globalToastMessage) {
                HubsManagmentService.globalToastMessage = this.toastr.error(`Real-time connection with server lost`, undefined, {
                  disableTimeOut: true,
                  tapToDismiss: false
                });
              }
            }
            if (nextRetry) {
              const message = `Connection with ${url} loose, attempt reconnection in ${nextRetry / 1000} seconds`;
              this.logger.warn(message);
              if (HubsManagmentService.toastMessageByUrlOnReconnection) {
                this.toastr.error(message);
              }
            } else {
              const message = `Connection with ${url} loose for ${retryContext.elapsedMilliseconds / 1000 / 60} minutes`;
              this.logger.error(message);
              if (HubsManagmentService.toastMessageByUrlOnReconnection) {
                this.toastr.error(message);
              }
            }

            return nextRetry;
          }
        })
        .build();
      
      if (!this.connectedHubs.get(url)) {
        this.connectedHubs.set(url, hub);
        hub.start()
        .then(() => {
          this.logger.info('Hub connection started: ' + url);
        })
        .catch(err => {
          this.logger.error(`Error while establishing connection to hub: ${url} - ${err}`);
        });
      } else {
        hub = this.connectedHubs.get(url);
      }

      hub.onreconnected((connectionId?: string) => {
        const message = `${url} reconnected`;
        const idx = HubsManagmentService.reconnectionsUrls.indexOf(url); 
        if (idx > -1) {
          HubsManagmentService.reconnectionsUrls.splice(idx, 1);
          if (HubsManagmentService.reconnectionsUrls.length == 0 && HubsManagmentService.globalToastMessage) {
            this.toastr.clear(HubsManagmentService.globalToastMessage.toastId);
            HubsManagmentService.globalToastMessage = undefined;
          }
        }

        this.logger.info(message);
        if (HubsManagmentService.toastMessageByUrlOnReconnection) {
          this.toastr.info(message);
        }
      });
    }
    return hub;
  }

  public disconnectHub(hub: HubConnection): Promise<void> {
    const url = hub.baseUrl;
    const p = hub.stop().then(() => {
      this.connectedHubs.delete(url);
      this.logger.info(`Hub ${url} disconnected`);
    });
    return p;
  }

  public getFromHub<T extends ApiMessage>(c: Constructor<T>,
                                hub: HubConnection,
                                hubMethodName: string): Observable<T> {
    const data$ = new Observable<T>(subscriber => {
      this.subscribeToHub(c, hub, hubMethodName, subscriber);
    });

    return data$;
  }

  private subscribeToHub<T extends ApiMessage>(c: Constructor<T>, hub: HubConnection, hubMethodName: string, subscriber: Subscriber<T>) {
    // Subscribe hub 
    hub.on(hubMethodName, (jsonItem: any) => {
      if (jsonItem) {
        this.logger.info('Get message from hub ' + hubMethodName);
        const data = createApiMessageInstance(c).loadFromJson(jsonItem);
        data.fromHub = true;
        subscriber.next(data);
      }
    });
  }

  public getFromControllerAndHub<T extends ApiMessage>(c: Constructor<T>,
                                hub: HubConnection,
                                hubMethodName: string,
                                controllerUrl: string, controllerOptions?: any): Observable<T> {
    // Prepare to get old data
    const dataFromRequest$ = this.http.get(controllerUrl, controllerOptions).pipe(
      map((jsonArray: object[]) => jsonArray.map(jsonItem => {
        const data = createApiMessageInstance(c).loadFromJson(jsonItem);
        return data;
      }))
    );

    const data$ = new Observable<T>(subscriber => {
      // Get old data
      dataFromRequest$.subscribe(dataList => {
        dataList.forEach((data) => {
          subscriber.next(data);
        });

        // Subscribe hub 
        this.subscribeToHub(c, hub, hubMethodName, subscriber);
      });
    });

    return data$;
  }

  public mapFromServer<T extends ApiMessage>(c: Constructor<T>, json) {
    return createApiMessageInstance(c).loadFromJson(json);
  }
}
