import { Injectable, OnInit } from '@angular/core';
import { Observable, Subscription, fromEvent, map, mapTo, merge, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  public online$: Observable<boolean> = new Observable();
  public offline$: Observable<boolean> = new Observable();

  constructor() {
    this.checkNetworkStatus();
  }
 
  checkNetworkStatus() {
    this.online$ = merge(
      of(null),
      fromEvent(window, 'online')
    ).pipe(map(() => navigator.onLine));
    this.offline$ = merge(
      of(null),
      fromEvent(window, 'offline')
    ).pipe(map(() => !navigator.onLine));
  }
}
