import { Type } from '@angular/core';
import { TestBed } from '@angular/core/testing';
import { MatDialogRef } from '@angular/material/dialog';
import { OAuthLogger } from 'angular-oauth2-oidc';
import { TestComponentOptions, Test } from 'angular-helpers';
import { ApisConfigurationProvider } from '../../config';
import { ApisTestingModule } from './apis-testing.module';

export class ApisTest<T> extends Test<T> {

  config: ApisConfigurationProvider;

  public initConfig() {
    this.config = TestBed.inject(ApisConfigurationProvider);
  }

  public initPage(type: Type<T>, options?: TestComponentOptions) {
    super.initPage(type, options);
  }

  public initDialog(type: Type<T>, options?: TestComponentOptions) {
    super.initDialog(type, options);
  }

  public initComponent(type: Type<T>, options?: TestComponentOptions) {
    if (!options) {
      options = {};
    }

    super.initComponent(type, {
      ...options,
      imports: [
        options.imports ?
          options.imports.concat([
            ApisTestingModule
          ]) : [ApisTestingModule]
      ]
    });
  }

  public initService(type: Type<T>, options?: TestComponentOptions) {
    if (!options) {
      options = {};
    }

    super.initService(type, {
      ...options,
      imports: [
        options.imports ?
          options.imports.concat([
            ApisTestingModule
          ]) : [ApisTestingModule]
      ]
    });
  }
}
