// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .mdc-list-item {
  padding: 0 !important;
}

mat-list {
  margin-top: 1em;
}

.item-list-infos {
  padding: 0.5em;
  display: flex;
  align-items: center;
}

.operator-info {
  display: flex;
  flex-direction: column;
  padding: 0.5em;
}

.operator-in-list {
  padding: 1em 0;
}
.operator-in-list:not(:last-child) {
  border-bottom: 1px solid;
}

.operator-with-edit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: -webkit-fill-available;
  width: -moz-available;
  margin-left: 0.5em;
}

#list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./projects/Apps/Semae/src/app/pages/list-operators-page/list-operators-page.component.scss"],"names":[],"mappings":"AAEA;EACE,qBAAA;AADF;;AAGA;EACE,eAAA;AAAF;;AAGA;EACE,cAAA;EACA,aAAA;EACA,mBAAA;AAAF;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,cAAA;AAAF;;AAGA;EACE,cAAA;AAAF;AAEE;EACE,wBAAA;AAAJ;;AAIA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,6BAAA;EAEA,qBAAA;EACA,kBAAA;AAFF;;AAIA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AADF","sourcesContent":["@import \"../../styles/variables.scss\";\r\n\r\n::ng-deep .mdc-list-item {\r\n  padding: 0!important;\r\n}\r\nmat-list {\r\n  margin-top: 1em;\r\n}\r\n\r\n.item-list-infos {\r\n  padding: 0.5em;\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.operator-info {\r\n  display: flex;\r\n  flex-direction: column;\r\n  padding: 0.5em;\r\n}\r\n\r\n.operator-in-list {\r\n  padding: 1em 0;\r\n\r\n  &:not(:last-child) {\r\n    border-bottom: 1px solid;\r\n  }\r\n}\r\n\r\n.operator-with-edit {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n  width: -webkit-fill-available;\r\n  //For Firefox\r\n  width: -moz-available;\r\n  margin-left: 0.5em;\r\n}\r\n#list-header {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
