import { Config } from 'angular-helpers';
import { ApisConfigurationInterface } from '../interfaces';

export class ApisConfig extends Config implements ApisConfigurationInterface {
  gatewayApi: string;
  exampleApi: string;
  logsApi: string;
  messagingApi: string;
  operatorsControlsApi: string;
}
