import { Injectable } from '@angular/core';
import { ConfigurationProvider, Configuration } from 'angular-helpers';
import { ApisConfig } from './apis-config.model';

@Injectable({ providedIn: 'root' })
export abstract class ApisConfigurationProvider extends ConfigurationProvider {
  abstract get params(): ApisConfig;

  public apiUseGateway(api: string): boolean {
    return api.startsWith(this.params.gatewayApi);
  }

  public getApiSuffix(api: string): string {
    return this.apiUseGateway(api) ? '' : '/api';
  }

  public getApiUrl(api: string, path?: string, subPath?: string, queryParams?: string | any): string {
    let url = api + this.getApiSuffix(api) + '/';
    if (path) {
      url += path;
    }
    if (subPath) {
      url += '/' + subPath;
    }
    if (queryParams) {
      if (typeof(queryParams) === 'string') {
        url += '?' + queryParams;
      } else {
        const params = new URLSearchParams();
        for (const key in queryParams) {
          if (queryParams.hasOwnProperty(key)) {
            params.set(key, queryParams[key]);
          }
        }
        url += '?' + params.toString();
      }
    }
    return url;
  }

  public getHubSuffix(api: string): string {
    return this.apiUseGateway(api) ? 'Hub' : '';
  }

  public getApiHubUrl(api: string, path?: string): string {
    let url = api + this.getHubSuffix(api) + '/';
    if (path) {
      url += path;
    }
    return url;
  }
}

@Injectable({ providedIn: 'root' })
export class DefaultApisConfiguration extends ApisConfigurationProvider {
  get params(): ApisConfig {
    // return default config
    return {
      production: false,
      baseUrl: 'http://localhost:4802',
      applicationName: 'Example Angular',
      applicationSubtitle: 'Angular example application',
      authJwtApi: 'https://localhost:48100/authJwt',
      authJwtApiOnGateway: false,
      authIS4Api: 'https://dev-frc-rc-rclms-sso-ui.azurewebsites.net',
      authIS4ClientId: 'ExampleUI',
      authIS4Scope: 'openid profile email roles ExampleAPI',
      authIS4SilentRefreshTimeout: 600000,
      authIS4TimeoutFactor: 0.25,
      authIS4AllowedUrls: 'https://localhost:48100',
      authAzureClientId: 'c75c766a-0da9-4710-b99e-96bf392ba7de',
      authAzureAuthority: 'https://login.microsoftonline.com/2659ef42-361c-4f17-9f8e-b2992668d9f3',
      authAzureApi: 'https://graph.microsoft.com',
      authAzureScope: 'User.Read',      
      authAzureAllowedUrls: '/operatorsControls/',
      authKeycloakServer: 'http://127.0.0.1:48080',
      authKeycloakRealm: 'keycloak-example',
      authKeycloakClientId: 'keycloak-example-clientid',
      authKeycloakResourceId: 'keycloak-example-resourceid',
      gatewayApi: 'https://localhost:48100',
      exampleApi: 'https://localhost:48100/example',
      operatorsControlsApi: 'https://localhost:48100/operatorsControls',
      logsApi: 'https://localhost:48100/logs',
      messagingApi: 'https://localhost:48100/messaging'
    };
  }
}

export class ApisConfiguration extends Configuration {
}
