import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApisConfigurationProvider } from '../../config';
import { NGXLogger } from 'ngx-logger';
import { HttpClientExtended } from 'angular-helpers';

@Injectable({
  providedIn: 'root',
})
export class ApisHttpClientExtended extends HttpClientExtended {
  constructor(protected http: HttpClient, 
    protected config: ApisConfigurationProvider, 
    protected logger: NGXLogger) {
    super(http, config, logger);
  }

  public getUrl(api: string, path: string) {
    return this.config.getApiUrl(api, path);
  }

  public getFromExampleApi(path: string, options?: any): Observable<any> {
    return this.get(this.getUrl(this.config.params.exampleApi, path), options);
  }

  public postToExampleApi(path: string, data?: any): Observable<object> {
    return this.post(this.getUrl(this.config.params.exampleApi, path), data);
  }

  public postMultiPartsWithFilesToExampleApi(path: string, files: File[], params?: any): Observable<any> {
    return this.postMultiPartsWithFiles(this.getUrl(this.config.params.exampleApi, path), 'files', files, params);
  }
}
