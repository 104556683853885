import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClientExtended } from '../http-extended/http-client-extended.service';



export class HealthCheck {
  name: string;
  status: string;
  totalDuration: string;
  entries: { [id: string]: HealthCheck };
}

@Injectable({
  providedIn: 'root'
})
export class HealthCheckService {

  constructor(protected http: HttpClientExtended) { }

  public healthChecks(apisURL: string[]): Promise<HealthCheck> {
    const promises = [];

    for (const url of apisURL) {
      promises.push(this.healthCheck(url));
    }

    return Promise.all(promises)
      .then((values) => {
        const checkAll = new HealthCheck();
        checkAll.name = 'self-check';
        if (values.length > 0) {
          checkAll.status = values.map(c => c.status).reduce((s1, s2) => {
            return (s1 !== 'Healthy' ? s1 : (s2 !== 'Healthy' ? s2 : 'Healthy'));
          });
          const totalDurationTime = values.map(c => this.totalDurationTime(c.totalDuration)).reduce((t1, t2) => t1 + t2);
          checkAll.totalDuration = this.calculateTotalDurationFromTime(totalDurationTime);
        }
        checkAll.entries = {};
        values.forEach((v) => {
          checkAll.entries[v.name] = v;
        });
        return checkAll;
      });
  }

  public healthCheck(apiUrl: string): Promise<HealthCheck> {
    const apiCheck$ = firstValueFrom(
      this.http.get(apiUrl + '/health').pipe(
        map(check => {
          const returnValue: HealthCheck = check as HealthCheck;
          returnValue.name = apiUrl;
          return returnValue;
        }))
    ).catch(r => {
      if (r.status === 503) {
        r.error.name = apiUrl;
        return r.error;
      } else {
        const returnValue = new HealthCheck();
        returnValue.name = apiUrl;
        returnValue.status = 'Unhealthy';
        return returnValue;
      }
    });
    return apiCheck$;
  }

  private totalDurationTime(totalDuration): number {
    if (totalDuration) {
      const time = totalDuration.split(':');
      let returnValue = (Number(time[0]) * 3600 + Number(time[1]) * 60 + Number(time[2].split('.')[0]));
      returnValue = returnValue * 10000000 + Number(time[2].split('.')[1]);
      return returnValue;
    } else {
      return 0;
    }
  }

  private calculateTotalDurationFromTime(time) {
    let seconds = time / 10000000;
    let minutes = Math.trunc(seconds / 60);
    const hours = Math.trunc(minutes / 60);
    seconds = seconds - minutes * 60;
    minutes = minutes - hours * 60;
    return (hours < 10 ? `0${hours}` : hours) + ':'
          + (minutes < 10 ? `0${minutes}` : minutes) + ':'
          + (seconds < 10 ? `0${seconds}` : seconds);
  }

}
