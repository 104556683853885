import { Component, EventEmitter, OnDestroy, OnInit, Output, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription, filter, lastValueFrom, takeUntil } from 'rxjs';
import { ApisConfigurationProvider } from 'apis-helpers';
import { ApisHealthCheckService } from 'apis-helpers';
import { SupportService, AssetsHelperService } from 'angular-helpers';
import { AuthentificationService } from 'angular-helpers';
import { IconsService } from './services/icons/icons.service';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject } from '@microsoft/signalr';
import { CouchdbService } from './services/couchdb-database/couchdb.service';
import { NetworkService } from './services/network/network.service';
import { routes } from './modules/app-routing.module';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @Output() loading: boolean;
  @Output() internetConnectionError: boolean;
  public title: string;
  public isLoggedIn$: Observable<boolean>;
  private subscriptions: Subscription[] = [];
  private readonly _destroying$ = new Subject<void>();

  public pages: any[] = null;

  constructor(private router: Router,
    private logger: NGXLogger,
    private config: ApisConfigurationProvider,
    private authentificationService: AuthentificationService,
    private healthCheckService: ApisHealthCheckService,
    private supportService: SupportService,
    private iconService: IconsService,
    private couchdbService: CouchdbService,
    private networkService: NetworkService) {

    if (document.location.pathname !== '/health') {
      this.title = this.config.params.applicationName;
    }
    this.pages = routes;
    this.iconService.register();
    this.supportService.initSupportForRouter();
    this.isLoggedIn$ = authentificationService.isAuthenticated$;
  }
  
  ngOnInit() {
    console.error('IsProdMode', !isDevMode());
    this.subscriptions.push(this.networkService.online$
      .subscribe(status => {
        // refresh token
        lastValueFrom(this.authentificationService.accessToken).catch((e) => {
          // if error = silent refresh failed => init login
          this.authentificationService.login();
        });
      }));

    this.logger.debug('route url: ' + this.router.url);
    if (document.location.pathname === '/health') {
      this.healthCheckService.healthChecks().then(check => {
        document.querySelector('html').innerText = JSON.stringify(check);
      });
    }
    if (navigator.onLine) {
      this.subscriptions.push(    
        this.isLoggedIn$.subscribe((res) => {
          if (res) { // if is authenticated
            this.couchdbService.loadCouchDb().then(() => {
              this.getAllOperators();
              this.getReferencesDataDB();
            })
          }
      }));
    } else {
      this.couchdbService.loadCouchDb().then(() => {
        this.getAllOperators();
        this.getReferencesDataDB();
      })
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  
  private getAllOperators(): void {
    this.loading = true;
    this.couchdbService.getOperatorFormCouchDB()
      .then((res) => this.internetConnectionError = false)
      .catch((err) => this.internetConnectionError = true)
      .finally(() => this.loading = false);
  }
  private getReferencesDataDB(): void {
    this.loading= true;
    this.couchdbService.getReferencesCouchDB()
      .catch((err) => console.error("error retrieving ref data db", err))
      .finally(() => this.loading = false);
  }

}
