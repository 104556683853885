import { Component, Input, ContentChild, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent {
  @Input() title: string;
  @Input() loading= false;
  @Input() internetConnectionError = false;
  @ContentChild('titleContent') titleContent: TemplateRef<any>;
  @ViewChild('page', { static: true }) page: any;
  @ViewChild('content', { static: true }) content: any;

  constructor() {}
}
