import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { AuthJwtService } from '../../services/auth-jwt.service';

@Component({
  selector: 'frmk-login-jwt',
  templateUrl: './login-jwt.component.html',
  styleUrls: ['./login-jwt.component.scss']
})
export class LoginJwtComponent implements OnInit {

  loginForm: UntypedFormGroup;

  // #region events
  @Output() loginSuccess = new EventEmitter();
  // #endregion

  constructor(private authJwtService: AuthJwtService, private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      login: [''],
      password: ['']
    });
  }

  get f() { return this.loginForm.controls; }

  login() {
    this.authJwtService.login(this.f.login.value, this.f.password.value)
    .subscribe(success => {
      if (success) {
        this.loginSuccess.emit();
      }
    });
  }

}
