import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AssetsHelperService, DefaultApiMessage, PouchDBHelperService, SynchronizedPouchDB, createApiMessageInstance } from "angular-helpers";
import { OperatorForm } from "apis-helpers/models/operator-from-couchdb.model";
import { ReferenceDataFromCouchDB } from "apis-helpers/models/reference-data-from-couchdb.model";
import { CookieService } from "ngx-cookie-service";
import { ToastrService } from "ngx-toastr";
import { Observable, firstValueFrom, lastValueFrom, map } from "rxjs";
import { HttpClientSemaeService } from "../http-client-semae.service";

@Injectable({
  providedIn: 'root'
})
export class CouchdbDatabaseService {
  public isOnline: boolean = true;
  public referencesDB: SynchronizedPouchDB;
  public operatorsFormDB: SynchronizedPouchDB;
  public couchdbServerUrl: string;
  public operatorForms$: Observable<OperatorForm[]>;
  public referencesComplianceStatus$: Observable<ReferenceDataFromCouchDB[]>;
  public referencesChoices$: Observable<ReferenceDataFromCouchDB[]>;
 


  constructor(
    public http: HttpClientSemaeService,
    public pouchDbHelper: PouchDBHelperService,
    public cookieService: CookieService,
    public assetsHelperService: AssetsHelperService,
    public toastr: ToastrService) {
    }

    public loadCouchDb(): Promise<any> {
      // online = call api
        return lastValueFrom(this.http.getFromOperatorsControlsApi('CouchDBByContext/GetCouchDBServerUrl'))
          .then((res) => {
            this.couchdbServerUrl = createApiMessageInstance(DefaultApiMessage).loadFromJson(res).message;
            this.cookieService.set('CouchDBServerUrl', this.couchdbServerUrl);
          });          
    }

  public getOperatorFormCouchDB(): Promise<any> {
    return firstValueFrom(
      this.http.postToOperatorsControlsApi('CouchDBByContext/GetOrCreateUserCouchDB'))
      .then(res => {
        const couchDBName = createApiMessageInstance(DefaultApiMessage).loadFromJson(res).message;
        this.operatorsFormDB = this.pouchDbHelper.getSynchronizedPouchDB(
          couchDBName,
          this.couchdbServerUrl
        );

        // Store DB infos in cookies
        if (!this.cookieService.get('OperatorsFormDBName')) {
          this.cookieService.set('OperatorsFormDBName', couchDBName);
        }
        this.initOperatorsFormDB(this.couchdbServerUrl, couchDBName);
      })
      .catch((err: HttpErrorResponse) => {
        const couchDBName = this.cookieService.get('OperatorsFormDBName');
        this.initOperatorsFormDB(this.couchdbServerUrl, couchDBName);
    });
  }

  public getReferencesCouchDB(): Promise<any> {
    if (this.couchdbServerUrl) {
      // Get references data only if not already get
      return this.referencesComplianceStatus$ == undefined || this.referencesChoices$ == undefined
        ? firstValueFrom(this.http.getFromOperatorsControlsApi('CouchDBByContext/GetReferenceDataCouchDb'))
            .then(res => {
              const couchDBName = createApiMessageInstance(DefaultApiMessage).loadFromJson(res).message;
              this.referencesDB = this.pouchDbHelper.getSynchronizedPouchDB(
                couchDBName,
                this.couchdbServerUrl
              );
  
              // Store DB infos in cookies
              this.cookieService.set('referencesDBName', couchDBName);
              this.initReferencesDB(this.couchdbServerUrl, couchDBName);
            })
            .catch((err: HttpErrorResponse) => {
              const couchDBName = this.cookieService.get('referencesDBName');
              this.initReferencesDB(this.couchdbServerUrl, couchDBName);
            })
        : Promise.resolve();
    }
  }

  protected initOperatorsFormDB(couchDBServerUrl: string, couchDBName: string) {
    this.init(this.operatorsFormDB, couchDBServerUrl, couchDBName);
    this.operatorForms$ = this.operatorsFormDB.getAllTypedData$<OperatorForm>('OperatorForm');
  }

  protected initReferencesDB(couchDBServerUrl: string, couchDBName: string) {
    this.init(this.referencesDB, couchDBServerUrl, couchDBName);
    // Get reference compliances and reference choices sorted by order param
    this.referencesComplianceStatus$ =
      this.sortByOrder(this.referencesDB.getAllTypedData$<ReferenceDataFromCouchDB>('OperationReport'));
    this.referencesChoices$ =
      this.sortByOrder(this.referencesDB.getAllTypedData$<ReferenceDataFromCouchDB>('ControlStatus'));
  }

  private init(synchronizedPouchDB: SynchronizedPouchDB, couchDBServerUrl: string, couchDBName: string) {
    synchronizedPouchDB = this.pouchDbHelper.getSynchronizedPouchDB(
      couchDBName,
      couchDBServerUrl
    );
  }

  getAttachmentOperatorForm(docID: string, fileName: string): Promise<File> {
    try {
      return this.operatorsFormDB.getAttachment(docID, fileName);
    } catch {
      this.toastr.error('Bases de données non initialisées. Veuillez vous connecter à internet.')
    }
  }

  addOperatorForm(data: OperatorForm) {
    try {
      this.operatorsFormDB.add(data) // Prefere 'put' to set manually _id (Guid.create().toString() by example)
      .then(() => {
        this.toastr.show("Ajout réussi");
      }).catch((err) => {
        this.toastr.error("Ajout en erreur");
        console.log(err);
      });
    } catch {
      this.toastr.error('Bases de données non initialisées. Veuillez vous connecter à internet.')
    }
  }

  updateOperatorForm(data: OperatorForm) {
    try {
      this.operatorsFormDB.update(data)
      .then(() => {
        this.toastr.show("Modification réussie");
      }).catch((err) => {
        this.toastr.error("Modification en erreur");
        console.log(err);
      });
    } catch {
      this.toastr.error('Base de données non initialisées. Veuillez vous connecter à internet.')
    }
  }

  deleteOperatorForm(data: OperatorForm) {
    try {
      this.operatorsFormDB.delete(data);
    } catch {
      this.toastr.error('Base de données non trouvées. Veuillez vous connecter à internet.')
    }
  }

  private sortByOrder(obs: Observable<any>): Observable<any> {
    return obs.pipe(map((data) => {
        data.sort((a, b) => {
            return a.order < b.order ? -1 : 1;
        });
        return data;
    }));
  }
}
