import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'acronym'
})
export class AcronymPipe implements PipeTransform {
  transform(text : string): string {
    if (text) {
      const words = text.split(' ');
      return words.reduce((accumulator, currentValue) => accumulator + currentValue[0], "").toUpperCase();
    } else {
      return text;
    }
  }
}
