import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

export class ObservableWithResult<T> {
  public constructor(public obj$: Observable<T>, public result: T) {
    
  }
}

export function createObservableWithResult<T>(sendResult: (result: ObservableWithResult<T>) => void) {
  return (source$: Observable<T>) => {
    return source$.pipe(
      tap((data: T) => {
        const objWithResult = new ObservableWithResult<T>(source$, data);
        sendResult(objWithResult);
      })
    );
  };
}