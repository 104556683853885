import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';


@Injectable({
  providedIn: 'root'
})
export class ImagesService {

  constructor(private logger: NGXLogger) { 
  }
  
  public imageToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
        
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }


  public resizeImageFiles(files: FileList, maxSize: number = 1280): Promise<File[]> {
    const promises = [];
    for (let i = 0; i < files.length; i++) {
      promises.push(this.resizeImageFile(files.item(i), maxSize));
    }
    return Promise.all(promises);
  }

  public resizeImageFile(file: File, maxSize: number = 1280): Promise<File> {
    this.logger.info(`Compress image ${file.name}`);
    var reader = new FileReader();
    return new Promise(resolve => {
      reader.onload = (e: any) => {
        const image: HTMLImageElement = new Image();
        image.onload = function() {
          var canvas=document.createElement("canvas");
          var context=canvas.getContext("2d");
          const ratio = maxSize / Math.max(image.height, image.width);
          if(ratio < 1) {
            canvas.width=image.width * ratio;
            canvas.height=image.height * ratio;
            context.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);
            canvas.toBlob((blob) => {
              const compressedFile = new File([blob], file.name, { type: file.type })
              resolve(compressedFile);
            }, file.type);
          } else {
            resolve(file);
          }
        }
        image.src = e.target.result;
      }
      reader.readAsDataURL(file)
    })
  }
}
