import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NumberHelperService {
  constructor() {}

  roundToNDecimals(targetNumber: number, numberOfDecimals: number): number {
    const multiplier: number = Math.pow(10, numberOfDecimals);

    return (
      Math.round((targetNumber + Number.EPSILON) * multiplier) / multiplier
    );
  }
}
