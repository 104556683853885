import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { IconsServiceHelper } from 'angular-helpers/services/icons/icons.service';

// List your svg icons here :
export enum Icons {
  Home = 'home',
  Edit = 'edit',
  Delete = 'delete',
  Users = 'users',
  Gps = 'pin-gps',
  Add = 'add',
  Login = 'log-in',
  Logout = 'log-out',
  ArrowUp = 'arrow-up'
}

@Injectable({
  providedIn: 'root'
})
export class IconsService extends IconsServiceHelper {

  constructor( matIconRegistery: MatIconRegistry,
    domSanitizer: DomSanitizer) {
    super(matIconRegistery, domSanitizer);
  }

  register(): void {
    this.registerIconSet(Icons, 'assets/icons/svg');
  }
}
