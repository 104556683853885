export class StringHelper {
    public static formatToCompareString(value: string): string {
        const reg = /[\u0300-\u036f]/g;
        return value ? value.toLowerCase().normalize('NFD').replace(reg, '') : '';
    }

    public static findTextIntoString(text: string, str: string): boolean {
        let returnValue: boolean;
        // First word begin by 'text' value
        returnValue = this.formatToCompareString(str).indexOf(this.formatToCompareString(text)) === 0;
        // Another word begin by 'text' value
        returnValue = returnValue || this.formatToCompareString(str).indexOf(this.formatToCompareString('' + text)) > 0;
        return returnValue;
    }

    public static stringBeginByText(str: string, text: string): boolean {
        return this.formatToCompareString(str).startsWith(this.formatToCompareString(text));
    }

    public static truncate(str: string, maxLength: number): string {
        if (str?.length > maxLength) {
            return str.substring(0, maxLength) + '...';
        } else {
            return str;
        }
    }

    public static isNullOrEmpty(str: string): boolean {
        return (str === undefined || str === null || str.length === 0);
    }

}
