import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthErrorEvent, OAuthService, OAuthSuccessEvent } from 'angular-oauth2-oidc';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ConfigurationProvider } from '../../config/config.provider';
import { IAuthIS4Service } from '../interfaces/auth-is4.interface';

@Injectable({ providedIn: 'root' })
export class AuthIS4Service implements IAuthIS4Service {
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  /**
   * Publishes `true` if and only if (a) all the asynchronous initial
   * login calls have completed or errorred, and (b) the user ended up
   * being authenticated.
   *
   * In essence, it combines:
   *
   * - the latest known state of whether the user is authorized
   * - whether the ajax calls for initial log in have all been done
   */
  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$
  ]).pipe(map(values => values.every(b => b)));

  public async navigateToLoginPage() {
    // TODO: Remember current URL
    await this.router.navigateByUrl('/should-login');
  }

  private setLoggerHeader(token: string) {
    const config = this.logger.getConfigSnapshot();
    config.customHttpHeaders = new HttpHeaders({'Authorization': `Bearer ${token}`});
    this.logger.updateConfig(config);
  }

  private removeLoggerHeader() {
    const config = this.logger.getConfigSnapshot();
    config.customHttpHeaders = new HttpHeaders();
    this.logger.updateConfig(config);
  }
  
  isConfigured(): boolean {
    if (this.config.params?.authIS4Api) {
      return true;
    } else {
      return false;
    }
  }
 
  constructor(
    private oauthService: OAuthService,
    private logger: NGXLogger,
    private router: Router,
    private config: ConfigurationProvider
  ) {
    if (this.isConfigured()) {
      // Clear header for logger 
      this.removeLoggerHeader();

      // Useful for debugging:
      this.oauthService.events.subscribe(event => {
        if (event instanceof OAuthErrorEvent) {
          if (event.params && (Object)(event.params).error === 'login_required') {
            this.logger.debug(event);
          } else if (event.type === 'silent_refresh_error'
            && event.reason instanceof OAuthErrorEvent
            && event.reason.params && (Object)(event.reason.params).error === 'login_required') {
            this.logger.debug(event);
          } else {
            this.logger.error(event);
          }
        } else if (event instanceof OAuthSuccessEvent && event.type === 'silently_refreshed') {
          this.logger.info(event);
          this.logger.debug('calling sendAlertUserConnected');
          // planningService.sendAlertUserConnected();
        } else {
          this.logger.debug(event);
        }
      });

      // This is tricky, as it might cause race conditions (where access_token is set in another
      // tab before everything is said and done there.
      // TODO: Improve this setup.
      window.addEventListener('storage', (event) => {
        // The `key` is `null` if the event was caused by `.clear()`
        if (event.key !== 'access_token' && event.key !== null) {
          return;
        }

        this.logger.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
        if (this.oauthService.hasValidAccessToken()) {
          this.isAuthenticatedSubject$.next(true);
          this.setLoggerHeader(this.oauthService.getAccessToken());
        } else {
          this.isAuthenticatedSubject$.next(false);
          this.removeLoggerHeader();
        }

        if (!this.oauthService.hasValidAccessToken()) {
          const p = this.navigateToLoginPage(); // eslint-disable-line @typescript-eslint/no-unused-vars
        }
      });

      this.oauthService.events
        .subscribe((e) => {
            if (['discovery_document_loaded', 'token_refreshed', 'token_expires', 'silently_refreshed'].includes(e.type)) {
              if (this.oauthService.hasValidAccessToken()) {
                this.isAuthenticatedSubject$.next(true);
                this.setLoggerHeader(this.oauthService.getAccessToken());
              } else {
                this.isAuthenticatedSubject$.next(false);
                this.removeLoggerHeader();
              }
            } else if (e.type === 'token_received') {
              const p = this.oauthService.loadUserProfile(); // eslint-disable-line @typescript-eslint/no-unused-vars
            } else if (['session_terminated', 'session_error'].includes(e.type)) {
              this.logger.debug(`Redirect to login page (${e.type})`);
              const p = this.navigateToLoginPage(); // eslint-disable-line @typescript-eslint/no-unused-vars    
            // } else {
            //   this.logger.debug(`Untreated IS4 event: ${e.type}`);
            }
        });

      this.oauthService.setupAutomaticSilentRefresh();
    }
  }

  public runInitialLoginSequence(): Promise<void> {
    if (location.hash) {
      this.logger.debug('Encountered hash fragment, plotting as table...');
      console.table(location.hash.substr(1).split('&').map(kvp => kvp.split('=')));
    }

    // 0. LOAD CONFIG:
    // First we have to check to see how the IdServer is
    // currently configured:
    return this.oauthService.loadDiscoveryDocument()

      // For demo purposes, we pretend the previous call was very slow
      // then(() => new Promise(resolve => setTimeout(() => resolve(), 1000)))

      // 1. HASH LOGIN:
      // Try to log in via hash fragment after redirect back
      // from IdServer from initImplicitFlow:
      .then(() => this.oauthService.tryLogin())

      .then(() => {
        if (this.oauthService.hasValidAccessToken()) {
          return Promise.resolve();
        }

        // 2. SILENT LOGIN:
        // Try to log in via a refresh because then we can prevent
        // needing to redirect the user:
        return this.oauthService.silentRefresh()
          .then(() => Promise.resolve())
          .catch(result => {
            // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
            // Only the ones where it's reasonably sure that sending the
            // user to the IdServer will help.
            const errorResponsesRequiringUserInteraction = [
              'interaction_required',
              'login_required',
              'account_selection_required',
              'consent_required',
            ];

            if (result
              && result.reason
              && errorResponsesRequiringUserInteraction.indexOf(result.reason.error) >= 0) {

              // 3. ASK FOR LOGIN:
              // At this point we know for sure that we have to ask the
              // user to log in, so we redirect them to the IdServer to
              // enter credentials.
              //
              // Enable this to ALWAYS force a user to login.
              // this.oauthService.initImplicitFlow();
              //
              // Instead, we'll now do this:
              this.logger.warn('User interaction is needed to log in, we will wait for the user to manually log in.');
              return Promise.resolve();
            }

            // We can't handle the truth, just pass on the problem to the
            // next handler.
            return Promise.reject(result);
          });
      })

      .then(async () => {
        this.isDoneLoadingSubject$.next(true);

        // Check for the strings 'undefined' and 'null' just to be sure. Our current
        // login(...) should never have this, but in case someone ever calls
        // initImplicitFlow(undefined | null) this could happen.
        if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
          let stateUrl = this.oauthService.state;
          if (stateUrl.startsWith('/') === false) {
            stateUrl = decodeURIComponent(stateUrl);
          }
          this.logger.debug(`There was state of ${this.oauthService.state}, so we are sending you to: ${stateUrl}`);
          await this.router.navigateByUrl(stateUrl);
        }
      })
      .catch((result) => {
        if (result instanceof OAuthErrorEvent && result.type === 'silent_refresh_error'
          && result.reason instanceof OAuthErrorEvent && (Object)(result.reason.params).error === 'login_required') {
            this.logger.warn('Identity Server 4: silent refresh failed');
        } else {
          this.logger.error('Identity Server 4 configuration error: ' + JSON.stringify(result));
        }
        this.isDoneLoadingSubject$.next(true);
      });
  }

  public login(targetUrl?: string) {
    // Note: before version 9.1.0 of the library you needed to
    // call encodeURIComponent on the argument to the method.
    this.oauthService.initLoginFlow(targetUrl || this.router.url);
  }

  public logout() {
    this.oauthService.logOut();
  }
  public async refresh() {
    await this.oauthService.silentRefresh();
  }
  public hasValidToken() { return this.oauthService.hasValidAccessToken(); }

  // These normally won't be exposed from a service like this, but
  // for debugging it makes sense.
  public get accessToken() { return this.oauthService.getAccessToken(); }
  public get refreshToken() { return this.oauthService.getRefreshToken(); }
  public get identityClaims() { return this.oauthService.getIdentityClaims(); }
  public get idToken() { return this.oauthService.getIdToken(); }
  public get logoutUrl() { return this.oauthService.logoutUrl; }
}
