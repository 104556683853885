import { NgModule } from '@angular/core';
import { TestingModule } from 'angular-helpers';
import { AppMaterialModule } from '../material.module';
import { ApisServicesModule } from '../apis-services.module';

@NgModule({
  imports: [
    TestingModule,
    AppMaterialModule,
    ApisServicesModule.forRoot()
  ],
  exports: [
    TestingModule,
    AppMaterialModule,
    ApisServicesModule
  ]
})
export class ApisTestingModule { }
