import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthJwtService } from '../services/auth-jwt.service';
import { LoginJwtDialogComponent } from '../dialogs/login-jwt-dialog/login-jwt-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AuthJwtGuard {

  constructor(private authJwtService: AuthJwtService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canLoad(route, state);
  }

  async canLoad(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authJwtService.hasToken()) {
      await this.router.navigate(['/nav/login'], { queryParams: { redirectTo: state.url } });
    }
    return this.authJwtService.hasToken();
  }

  public async canExecute(next: () => void) {
    const hasToken = this.authJwtService.hasToken();
    if (this.authJwtService.hasToken()) {
      next();
    } else {
      this.authJwtService.openLoginDialog(LoginJwtDialogComponent).subscribe(() => {
        if (this.authJwtService.hasToken()) {
          next();
        }
      });
    }
  }
}
