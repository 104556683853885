import { EntityFromCouchDB } from 'angular-helpers';
import { ReferenceDataFromCouchDB } from './reference-data-from-couchdb.model';

export class OperatorForm implements EntityFromCouchDB {
  constructor(
    public _id?: string,
    public idInspector?: string,
    public _rev?: string,
    public split_discriminator: string = 'OperatorForm',
    public operatorName?: string,
    public date?: string,
    public comment?: string,
    public complianceStatus?: ReferenceDataFromCouchDB,
    public choice?: ReferenceDataFromCouchDB,
    public _attachments?: JSON, 
  ) {
  }
}
