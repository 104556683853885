import { Injectable } from '@angular/core';
import { of, Observable, BehaviorSubject, firstValueFrom } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';
import { JwtTokens } from '../models/jwt-tokens';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { HttpClientExtended } from '../../services/http-extended/http-client-extended.service';
import { HttpHeaders } from '@angular/common/http';
import { ConfigurationProvider } from '../../config/config.provider';
import { IAuthJwtService } from '../interfaces/auth-jwt.interface';
import { Buffer } from 'buffer';
import { ComponentType } from '@angular/cdk/portal';
import { ILoginJwtDialogComponent } from '../interfaces/login-jwt-dialog.interface';


@Injectable({
  providedIn: 'root'
})
export class AuthJwtService implements IAuthJwtService {
  private readonly isLoginSubject: BehaviorSubject<boolean>;
  private readonly jwtTokensSubject: BehaviorSubject<JwtTokens>;
  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private readonly JWT_TOKEN_DATA = 'JWT_TOKEN_DATA';
  private refreshTokenInProgress: Promise<JwtTokens>;

  constructor(private http: HttpClientExtended,
    private logger: NGXLogger,
    private config: ConfigurationProvider, 
    public dialog: MatDialog) {
    this.isLoginSubject = new BehaviorSubject<boolean>(this.hasToken());
    this.jwtTokensSubject = new BehaviorSubject<JwtTokens>(this.getJwtTokenData());
    if (this.isConfigured() && this.hasToken()) {
      this.setLoggerHeader();
    }
  }

  isConfigured(): boolean {
    if (this.config.params?.authJwtApi) {
      return true;
    } else {
      return false;
    }
  }

  private setLoggerHeader() {
    const token = this.getJwtToken();
    const config = this.logger.getConfigSnapshot();
    config.customHttpHeaders = new HttpHeaders({'Authorization': `Bearer ${token}`});
    this.logger.updateConfig(config);
  }

  private removeLoggerHeader() {
    const config = this.logger.getConfigSnapshot();
    config.customHttpHeaders = new HttpHeaders();
    this.logger.updateConfig(config);
  }

  openLoginDialog<T = ILoginJwtDialogComponent>(component: ComponentType<T>): Observable<any> {
      // Open window to login
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      const dialogRef = this.dialog.open(component, dialogConfig);
      return dialogRef.afterClosed();
  }

  login(login: string, password: string): Observable<boolean> {
    return this.http.postToAuthApi('/authenticate/login', { login: login, password: password })
      .pipe(
        tap((tokens: JwtTokens) => this.doLoginUser(login, tokens)),
        mapTo(true),
        catchError(error => {
          if (error.status === 401) {
            alert('Accès non autorisé');
          } else {
            this.logger.error(error.message);
          }
          return of(false);
        }));
  }

  logout(): Observable<boolean> {
    return this.http.postToAuthApi('/authenticate/logout').pipe(
      mapTo(true),
      catchError(error => {
        if (error.status === 401) {
          alert('Accès non autorisé');
        } else {
          this.logger.error(error.message);
        }
        return of(false);
      }),
      tap(() => this.doLogoutUser())
    );
  }

  isLoggedIn(): Observable<boolean> {
    return this.isLoginSubject.asObservable();
  }

  getJwtToken$(): Observable<JwtTokens> {
    return this.jwtTokensSubject.asObservable();
  }

  hasToken(): boolean {
    return !!this.getJwtToken();
  }

  refreshToken(): Observable<JwtTokens> {
    return this.http.postToAuthApi('/authenticate/refresh', {
      refreshToken: this.getRefreshToken()
    }).pipe(tap((tokens: JwtTokens) => {
      this.storeTokens(tokens);
      this.refreshTokenInProgress = undefined;
    }));
  }

  refreshTokenPromise(): Promise<JwtTokens> {
    if (!this.refreshTokenInProgress) {
      this.refreshTokenInProgress = firstValueFrom(this.refreshToken());
    }
    return this.refreshTokenInProgress;
  }

  getRefreshUrl(): string {
    const suffix = (this.config.params.authJwtApiOnGateway) ? '' : '/api';
    const url = this.config.params.authJwtApi + suffix + '/authenticate/refresh';
    return url;
  }

  isTokenExpired() {
    const token = this.getJwtToken();
    if (token) {
      const v = token.split('.')[1];
      // const d = atob(v);
      const d = Buffer.from(v, 'base64').toString('ascii');
      const expiry = (JSON.parse(d)).exp;
      return expiry * 1000 < Date.now();
    } else {
      return true;
    }
  }

  getJwtToken(): string {
    return localStorage.getItem(this.JWT_TOKEN);
  }
  getJwtTokenData(): JwtTokens {
    return JSON.parse(localStorage.getItem(this.JWT_TOKEN_DATA)) as JwtTokens;
  }

  private doLoginUser(login: string, tokens: JwtTokens) {
    if (tokens.errorMessage) {
      alert(tokens.errorMessage);
    } else {
      this.storeTokens(tokens);
      this.setLoggerHeader();
    }
  }

  public doLogoutUser() {
    this.removeTokens();
    this.removeLoggerHeader();
  }

  private getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  private storeTokens(tokens: JwtTokens) {
    localStorage.setItem(this.JWT_TOKEN, tokens.token);
    localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
    localStorage.setItem(this.JWT_TOKEN_DATA, JSON.stringify(tokens));
    this.isLoginSubject.next(true);
    this.jwtTokensSubject.next(tokens);
  }

  private removeTokens() {
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
    localStorage.removeItem(this.JWT_TOKEN_DATA);
    this.isLoginSubject.next(false);
    this.jwtTokensSubject.next(undefined);
  }
}
