import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgxCaptureService } from 'ngx-capture';
import { NGXLogger } from 'ngx-logger';
import { firstValueFrom } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ImagesService } from '../images/images.service';


@Injectable({
  providedIn: 'root'
})
export class SupportService {

  constructor(
    private router: Router,
    private http: HttpClient,
    private logger: NGXLogger,
    private imagesService: ImagesService, 
    private captureService: NgxCaptureService) { 
  }

  public initSupportForRouter() {
    this.router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd)
    ).subscribe(( event: NavigationEnd) => {
      if (event.url) {
        this.logger.info(`Open url ${event.url}`);
      }
    });
  }
  
  // @ViewChild('content', { static: true }) content: any;
  public sendScreenshotToServer(url: string, screen?: any): Promise<void> {
    this.logger.info(`Take screenshot to send to ${url}`);
    const content = screen ? screen.nativeElement : document.body;
    const p = firstValueFrom(this.captureService.getImage(content, true)).then(img => {
      if (img === 'data:,') {
        this.logger.warn('Screenshot is empty, plsease check component size');
      }
      const blob = this.imagesService.imageToBlob(img);
      const formData = new FormData();
      formData.append('files', blob, 'screenshot.png');
      this.http.post(url, formData).subscribe();
    });
    return p;
  }
}
