import { Injectable } from '@angular/core';
import { GeolocalisationServiceInterface } from './geolocalisation-service-interface';

@Injectable({
  providedIn: 'root'
})
export class GeolocalisationService implements GeolocalisationServiceInterface{
  getCurrentPosition(successCallback: PositionCallback, errorCallback?: PositionErrorCallback | null): void {
    if (navigator.geolocation) {
      const options = {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0,
      };
      navigator.geolocation.getCurrentPosition(
        successCallback,
        errorCallback,
        options
        );
    } else if (errorCallback) {
      let error = new GeolocationServiceError(
        GeolocationPositionError.POSITION_UNAVAILABLE,
        "Le navigateur ne possède pas de position GPS."
      );
      errorCallback(error);
    }
  }
}

class GeolocationServiceError implements GeolocationPositionError {
  code: number;
  message: string;
  readonly PERMISSION_DENIED: 1 = 1;
  readonly POSITION_UNAVAILABLE: 2 = 2;
  readonly TIMEOUT: 3 = 3;

  constructor(code: number, message: string) {
    this.code = code;
    this.message = message;
  }
}
