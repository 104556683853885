import { ErrorHandler, InjectionToken, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApisHttpClientExtended } from '../services/apis-http-client-extended/apis-http-client-extended.service';
import { ApisHubsManagmentService } from '../services/apis-hubs-managment/apis-hubs-managment.service';
import { ApisConfiguration, ApisConfigurationProvider, DefaultApisConfiguration } from '../config';
import { AuthConfig, OAuthModuleConfig } from 'angular-oauth2-oidc';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {
  HttpErrorsInterceptor, JwtTokenInterceptor, AuthJwtService, AuthJwtModule, ConfigurationProvider,
  resolveAuthIS4ModuleConfig, AuthKeycloakModule, KeycloakInterceptor, UncatchedErrorHandler, resolveAuthKeycloakConfig, KeycloakTokenService
} from 'angular-helpers';
import { AuthIS4Service, resolveAuthIS4Config, AuthIS4Module } from 'angular-helpers';
import { NgxCaptureModule } from 'ngx-capture';
import { ToastrModule } from 'ngx-toastr';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalService } from '@azure/msal-angular';
import { AuthAzureModule, AuthAzureService } from 'angular-helpers/auth-azure';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from 'angular-helpers/auth-azure/auth-azure-config';
export const KEYCLOAK_CONFIG_TOKEN = new InjectionToken('KEYCLOAK_CONFIG_TOKEN');

@NgModule({
  imports: [
    CommonModule,
    NgxCaptureModule,
    ToastrModule.forRoot()
  ],
  exports: [
    AuthKeycloakModule,
    AuthJwtModule,
    AuthAzureModule,
    AuthIS4Module,
    ToastrModule,
    MsalModule
  ]
})



export class ApisServicesModule {

  constructor(@Optional() @SkipSelf() parentModule: ApisServicesModule) {
    if (parentModule) {
      throw new Error('ApisServicesModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(apisConfiguration: ApisConfiguration = {}): ModuleWithProviders<ApisServicesModule> {
    const apisConfigProvider = apisConfiguration.config || {
      provide: ApisConfigurationProvider, useClass: DefaultApisConfiguration
    };
    const configProvider = {
      provide: ConfigurationProvider, useClass: apisConfigProvider['useClass']
    };

    return {
      ngModule: ApisServicesModule,
      providers: [
        ApisHttpClientExtended,
        ApisHubsManagmentService,
        AuthJwtService,
        AuthIS4Service,
        AuthAzureService,
        apisConfigProvider,  
        MsalGuard,
        MsalBroadcastService,
        MsalService,
        provideHttpClient(withInterceptorsFromDi()),
        { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory, deps: [ApisConfigurationProvider] },
        { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory, deps: [ApisConfigurationProvider] },
        { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MSALInterceptorConfigFactory, deps: [ApisConfigurationProvider] },
        configProvider,
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: KeycloakInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtTokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorsInterceptor, multi: true },
        { provide: KEYCLOAK_CONFIG_TOKEN, useFactory: resolveAuthKeycloakConfig, deps: [ApisConfigurationProvider, KeycloakTokenService] },

        { provide: AuthConfig, useFactory: resolveAuthIS4Config, deps: [ApisConfigurationProvider] },
        { provide: OAuthModuleConfig, useFactory: resolveAuthIS4ModuleConfig, deps: [ApisConfigurationProvider] },
        { provide: ErrorHandler, useClass: UncatchedErrorHandler }
      ]
    };
  }
}
