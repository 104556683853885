import { EventEmitter } from '@angular/core';
import { MonoTypeOperatorFunction, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { createApiMessageInstance, Entity, EntityWithKey, RealtimeEntitiesEvent } from '../../models';
import { NGXLogger } from 'ngx-logger';
import { HttpClientExtended } from '../http-extended/http-client-extended.service';
import { HubConnection } from '@microsoft/signalr';
import { HubsManagmentService } from '../hubs-managment/hubs-managment.service';
import { createObservableWithResult, createSubjectWithResult, ObservableWithResult, SubjectWithResult } from '../../operators';
import { ControllerFromApi } from '../controller-from-api/controller-from-api.service';
import { UpdatableGetterInterface } from '../../interfaces/updatable-getter.interface';
import { Constructor } from '../../helpers/constructor';

export class Getter<T extends EntityWithKey<TKey>, TKey extends number | string> 
              extends ControllerFromApi
              implements UpdatableGetterInterface<T, TKey> {
  public beforeRealtimeEvent: EventEmitter<RealtimeEntitiesEvent<T, TKey>> = new EventEmitter(false);
  public beforeRealtimeAddedEvent: EventEmitter<RealtimeEntitiesEvent<T, TKey>> = new EventEmitter(false);
  public beforeRealtimeUpdatedEvent: EventEmitter<RealtimeEntitiesEvent<T, TKey>> = new EventEmitter(false);
  public beforeRealtimeRemovedEvent: EventEmitter<RealtimeEntitiesEvent<T, TKey>> = new EventEmitter(false);

  public realtimeEvent: EventEmitter<RealtimeEntitiesEvent<T, TKey>> = new EventEmitter();
  public realtimeAddedEvent: EventEmitter<RealtimeEntitiesEvent<T, TKey>> = new EventEmitter();
  public realtimeUpdatedEvent: EventEmitter<RealtimeEntitiesEvent<T, TKey>> = new EventEmitter();
  public realtimeRemovedEvent: EventEmitter<RealtimeEntitiesEvent<T, TKey>> = new EventEmitter();

  protected allSingleData = new Map<TKey, Map<string, T | SubjectWithResult<T>>>();
  protected allDataLists = new Map<string, T[] | ObservableWithResult<T[]>>();
  protected dataListFilters = new Map<string, (T) => boolean>();
  protected realtimeHubConnection: HubConnection;

  constructor(protected http: HttpClientExtended, protected logger: NGXLogger, protected hubsManagment: HubsManagmentService,
              protected controllerBaseUrl: string, protected constructorType: Constructor<T>) { 
    super(http, logger, controllerBaseUrl);
  }

  protected initRealtimeEntities(hubBaseUrl: string, typeMessage?: string, realtimeHubConnectionWithAuth: boolean = false) {
    if (!typeMessage) {
      const dummyInstance = createApiMessageInstance(this.constructorType);
      typeMessage = dummyInstance.constructor.name;
    }

    if (hubBaseUrl) {
      if (realtimeHubConnectionWithAuth) {
        this.realtimeHubConnection = this.hubsManagment.connectHub(hubBaseUrl + 'RealtimeEntitiesWithAuthHub', true);
      } else {
        this.realtimeHubConnection = this.hubsManagment.connectHub(hubBaseUrl + 'RealtimeEntitiesHub', false);
      }
      this.realtimeHubConnection.on('RealtimeEvent', (json: any) => {
        if (json["obj"]["typeMessage"] === typeMessage) {
          this.onRealtimeEvent(json);
          const realtimeEvent = new RealtimeEntitiesEvent<T, TKey>(this.constructorType, json);
          this.onRealtimeEventEntity(realtimeEvent);
        }
      });
    }
  }

  protected isAttributeToCopy(key: string) {
    return (key !== undefined);
  }

  protected onRealtimeEvent(json: any) {
    if (json.eventType && json.obj.typeMessage) {
      this.logger.info(`onRealtimeEvent(${json.eventType}, ${json.obj.typeMessage})`);
    } else {
      this.logger.info(`onRealtimeEvent(${json})`);
    }
  }

  protected onRealtimeEventEntity(realtimeEvent: RealtimeEntitiesEvent<T, TKey>) {
    if (realtimeEvent) {
      this.beforeRealtimeEvent.emit(realtimeEvent);
      switch (realtimeEvent.eventType) {
        case "Added":
          this.beforeRealtimeAddedEvent.emit(realtimeEvent);
          this.refreshDataInternal(realtimeEvent.obj);
          this.realtimeAddedEvent.emit(realtimeEvent);
          break;
        case "Updated":
          this.beforeRealtimeUpdatedEvent.emit(realtimeEvent);
          this.refreshDataInternal(realtimeEvent.obj);
          this.realtimeUpdatedEvent.emit(realtimeEvent);
          break;
        case "Removed":
          this.beforeRealtimeRemovedEvent.emit(realtimeEvent);
          this.refreshDeletedDataInternal(realtimeEvent.obj);
          this.realtimeRemovedEvent.emit(realtimeEvent);
          break;
      }
      this.realtimeEvent.emit(realtimeEvent);
    }
  }

  private getDataFromArrayOrObjWithResult(arrayOrObjWithResult: T[] | ObservableWithResult<T[]>): T[] {
    let dataList: T[];
    if (Array.isArray(arrayOrObjWithResult)) {
      dataList = arrayOrObjWithResult;
    } else {
      dataList = arrayOrObjWithResult.result;
    }
    return dataList;
  }

  public setSingleDataInRealtimeCache(key: string, dataOrSubjectWithResult: T | SubjectWithResult<T>) {
    let data: T;
    if (dataOrSubjectWithResult instanceof Entity) {
      data = dataOrSubjectWithResult;
    } else {
      data = (dataOrSubjectWithResult as SubjectWithResult<T>).result;
    }
    if (!this.allSingleData.has(data.GetKey())) {
      this.allSingleData.set(data.GetKey(), new Map<string, SubjectWithResult<T>>());
    }
    this.logger.info(`Set single data cache ${key}`);
    this.allSingleData.get(data.GetKey()).set(key, dataOrSubjectWithResult);
  }

  public getDataListInRealtimeCache(key: string): T[] | ObservableWithResult<T[]> {
    return this.allDataLists.get(key);
  }

  public setDataListInRealtimeCache(key: string, dataList: T[] | ObservableWithResult<T[]>) {
    this.allDataLists.set(key, dataList);
  }

  public getAllDataInCache(call: (data: T) => void) {
    this.allSingleData.forEach((value: Map<string, SubjectWithResult<T>>, key2: TKey) => {
      value.forEach((subjectWithResult: SubjectWithResult<T>) => {
        call(subjectWithResult.result);
        subjectWithResult.sub$.next(subjectWithResult.result);
      });
    });
    
    this.allDataLists.forEach((arrayOrObjWithResult: T[] | ObservableWithResult<T[]>) => {
      const dataList = this.getDataFromArrayOrObjWithResult(arrayOrObjWithResult);
      dataList.forEach((data: T) => {
        call(data);
      });
    });
  }

  // Update data in observables
  public getDataForUpdate(key: TKey, updateCall: (data: T) => void) {
    this.allDataLists.forEach((arrayOrObjWithResult: T[] | ObservableWithResult<T[]>) => {
      const dataList = this.getDataFromArrayOrObjWithResult(arrayOrObjWithResult);
      const idx = dataList.findIndex(d => d.GetKey() === key);
      if (idx >= 0) {
        const data = dataList[idx];
        updateCall(data);
      }
    });

    this.allSingleData.forEach((value: Map<string, SubjectWithResult<T>>, key2: TKey) => {
      if (key === key2) {
        value.forEach((subjectWithResult: SubjectWithResult<T>) => {
          updateCall(subjectWithResult.result);
          subjectWithResult.sub$.next(subjectWithResult.result);
        });
      }
    });
  }

  public getDataFromCache(key: TKey): T {
    let data: T;    
    
    this.allDataLists.forEach((arrayOrObjWithResult: T[] | ObservableWithResult<T[]>) => {
      if (!data) {
        const dataList = this.getDataFromArrayOrObjWithResult(arrayOrObjWithResult);
        const idx = dataList.findIndex(d => d.GetKey() === key);
        if (idx >= 0) {
          data = dataList[idx];
        }
      }
    });

    if (!data) {
      this.allSingleData.forEach((value: Map<string, SubjectWithResult<T>>, key2: TKey) => {
        if (!data && key === key2) {
          value.forEach((subjectWithResult: SubjectWithResult<T>) => {
            data = subjectWithResult.result;
          });
        }
      });
    }

    return data;
  }
  
  // Refresh observables
  public refreshData() {
    this.allSingleData.forEach(
        (value: Map<string, SubjectWithResult<T>>, 
        key2: TKey) => { // eslint-disable-line @typescript-eslint/no-unused-vars
      value.forEach((subjectWithResult: SubjectWithResult<T>) => {
        subjectWithResult.sub$.next(subjectWithResult.result);
      });
    });
    this.allDataLists.forEach((arrayOrObjWithResult: T[] | ObservableWithResult<T[]>) => {
      if (!Array.isArray(arrayOrObjWithResult)) {
        arrayOrObjWithResult.obj$ = of(arrayOrObjWithResult.result);
      }
    });
  }

  // #region Internal refresh methods
  // Internal refresh of data (create or update)
  protected copyAttributes(from: T, to: T) {
    this.logger.debug(`CopyAttributes from ${from.GetTextValue()} to ${to.GetTextValue()})`);
    Object.keys(from).forEach(key => {
      if (this.isAttributeToCopy(key) && to.hasOwnProperty(key)) {
        if (to[key] instanceof Array) {
          if (from[key] !== null && from[key] !== undefined) {
            to[key] = from[key];
          }
        } else {
          to[key] = from[key];
        }        
      }
    });
  }

  private refreshDataInternal(data: T) {
    // Refresh data from getSingleDataByUrl
    this.allSingleData.forEach((value: Map<string, SubjectWithResult<T>>, key: TKey) => {
      if (key === data.GetKey()) {
        value.forEach((subjectWithResult: SubjectWithResult<T>) => {
          // subjectWithResult.result = data;
          this.copyAttributes(data, subjectWithResult.result);
          subjectWithResult.sub$.next(subjectWithResult.result);
        });
      }
    });

    // Refresh data from getMultipleDataByUrl
    this.allDataLists.forEach((arrayOrObjWithResult: T[] | ObservableWithResult<T[]>, key: string) => {
      const filter = this.dataListFilters.get(key);
      const dataList = this.getDataFromArrayOrObjWithResult(arrayOrObjWithResult);
      const idx = dataList.findIndex(d => d.GetKey() === data.GetKey());
      if (idx >= 0) {
        // Item found
        if (!filter || filter(data) === true) {
          // Refresh item in list
          // dataList[idx] = data;
          this.copyAttributes(data, dataList[idx]);
        } else {
          // It no longer meets the eligibility criteria of the list membership
          dataList.splice(idx, 1);
        }
      } else {
        // Item not found, add it
        if (!filter || filter(data) === true) {
          dataList.push(data);
        }
      }
    });
  }

  // Internal refresh of data (delete)
  private refreshDeletedDataInternal(deletedData: T) {
    // Refresh data from getSingleDataByUrl
    this.allSingleData.forEach((value: Map<string, SubjectWithResult<T>>, key: TKey) => {
      if (key === deletedData.GetKey()) {
        value.forEach((subjectWithResult: SubjectWithResult<T>) => {
          subjectWithResult.result = deletedData;
          subjectWithResult.sub$.next(null);
        });
      }
    });

    // Refresh data from getMultipleDataByUrl
    this.allDataLists.forEach((arrayOrObjWithResult: T[] | ObservableWithResult<T[]>) => {
      const dataList = this.getDataFromArrayOrObjWithResult(arrayOrObjWithResult);
      const idx = dataList.findIndex(d => d.GetKey() === deletedData.GetKey());
      if (idx >= 0) {
        dataList.splice(idx, 1);
      }
    });
  }

  protected refreshDataFromPipe(): MonoTypeOperatorFunction<T> {
    return tap<T>(data => {
      if (this.allDataLists.size > 0) {
        this.refreshDataInternal(data);
      }
    });
  }

  protected refreshMultipleDataFromPipe(): MonoTypeOperatorFunction<T[]> {
    return tap<T[]>(dataList => {
      if (this.allDataLists.size > 0) {
        dataList.forEach(data => {
          this.refreshDataInternal(data);
        });
      }
    });
  }

  protected refreshDeletedDataFromPipe(): MonoTypeOperatorFunction<T> {
    return tap<T>(deletedData => {
      if (this.allDataLists.size > 0) {
        this.refreshDeletedDataInternal(deletedData);
      }
    });
  }

  protected refreshMultipleDeletedDataFromPipe(): MonoTypeOperatorFunction<T[]> {
    return tap<T[]>(deletedList => {
      if (this.allDataLists.size > 0) {
        deletedList.forEach(deletedData => {
          this.refreshDeletedDataInternal(deletedData);
        });
      }
    });
  }
  // #endregion

  // #region Getters
  public getSingleDataByUrl(url: string, cacheNameForRealtimeUpdate?: string): Observable<T> {
    let obsResult$ = this.getSingleDataByUrlTyped<T>(url, this.constructorType);
    if (cacheNameForRealtimeUpdate) {
      obsResult$ = obsResult$.pipe(
        createSubjectWithResult<T>((result) => {
          this.setSingleDataInRealtimeCache(cacheNameForRealtimeUpdate, result);
        })
      );
    }
    return obsResult$;
  }

  /**
   * Get data list from url
   * @param url request url
   * @param options optional options
   *  forceReload(boolean): force to get httpRequest without using local cache
   *  cacheNameForRealtimeUpdate? (string): key used by local cache for realtime process
   *  filterForRealtimeUpdate?((data: T) => boolean): filter used by realtime cache to know if data need to be added or removed 
   */
  public getMultipleDataByUrl(url: string, options?: { 
      forceReload: boolean;
      cacheNameForRealtimeUpdate?: string;
      filterForRealtimeUpdate?: (data: T) => boolean;
    }): Observable<T[]> {

    const opt = Object.assign({}, {
      forceReload: false
    }, options);

    const allDataObsWithResult = this.getDataListInRealtimeCache(opt.cacheNameForRealtimeUpdate);
    if (!allDataObsWithResult || opt.forceReload) {
      let obsResult$ = this.getMultipleDataByUrlTyped(url, this.constructorType);
      if (opt.cacheNameForRealtimeUpdate) {
        obsResult$ = obsResult$.pipe(
          createObservableWithResult<T[]>((result) => {
            this.setDataListInRealtimeCache(opt.cacheNameForRealtimeUpdate, result);
            this.logger.info(`Set data list cache ${opt.cacheNameForRealtimeUpdate}`);
            if (opt.filterForRealtimeUpdate) {
              this.logger.debug(`Data list cache ${opt.cacheNameForRealtimeUpdate} defined a specific filter`);
              this.dataListFilters.set(opt.cacheNameForRealtimeUpdate, opt.filterForRealtimeUpdate);
            }
          })
        );
      }
      return obsResult$;
    } else {
      return (allDataObsWithResult as ObservableWithResult<T[]>).obj$;
    }
  }

  public getAllData(withAutoRefresh: boolean = false, forceReload: boolean = false): Observable<T[]> {
    const cacheNameForRealtimeUpdate: string = ( withAutoRefresh ? 'allData' : undefined );
    return this.getMultipleDataByUrl(this.getUrlFromController(), { 
      forceReload: forceReload,
      cacheNameForRealtimeUpdate: cacheNameForRealtimeUpdate
    });
  }

  public getSingleDataByKeyFromAll(key: TKey): Observable<T> {
    return this.getAllData(true).pipe(
      map((all: T[]) => all.find(d => d.GetKey() == key))
    );
  }

  public getSingleDataByKeyFromCache(key: TKey): T {
    return this.getDataFromCache(key);
  }

  public getSingleDataByKey(key: TKey, withAutoRefresh: boolean = false): Observable<T> {
    this.logger.debug(`Get data in ${this.controllerBaseUrl} with key ${key}`);
    const cacheNameForRealtimeUpdate: string = ( withAutoRefresh ? 'singleDataByKey-' + key : undefined );
    return this.getSingleDataByUrl(this.getUrlFromController(`${key}`), cacheNameForRealtimeUpdate);
  }

  public getMultipleDataByKeys(keys: TKey[], withAutoRefresh: boolean = false, forceReload: boolean = false): Observable<T[]> {
    this.logger.info(`Get data in ${this.controllerBaseUrl} with keys ${keys}`);
    if (keys.length > 0) {
      const queryParams = keys.map((k) => `keys=${k}`).join('&');
      const cacheNameForRealtimeUpdate: string = ( withAutoRefresh ? 'multipleData-' + queryParams : undefined );
      let filterForRealtimeUpdate: any;
      if (withAutoRefresh) {
        filterForRealtimeUpdate = (data: T) => keys.indexOf(data.GetKey()) !== -1;
      }
      return this.getMultipleDataByUrl(this.getUrlFromController('', queryParams), { 
        forceReload: forceReload,
        cacheNameForRealtimeUpdate: cacheNameForRealtimeUpdate, 
        filterForRealtimeUpdate: filterForRealtimeUpdate
      });
    } else {
      return of<T[]>([]);
    }
  }
  // #endregion
}
