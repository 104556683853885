import { Injectable, Provider } from '@angular/core';
import { Config } from './config.model';

@Injectable({ providedIn: 'root' })
export abstract class ConfigurationProvider {
  abstract get params(): Config;
}

@Injectable({ providedIn: 'root' })
export class DefaultConfiguration extends ConfigurationProvider {
  get params(): Config {
    // return default config
    return {
      production: false,
      baseUrl: 'http://localhost:4802',
      applicationName: 'Semae Angular',
      applicationSubtitle: 'Angular example application',
      authJwtApi: 'https://localhost:48100/authJwt',
      authJwtApiOnGateway: true,
      authIS4Api: 'https://dev-frc-rc-rclms-sso-ui.azurewebsites.net',
      authIS4ClientId: 'ExampleUI',
      authIS4Scope: 'openid profile email roles ExampleAPI',
      authIS4SilentRefreshTimeout: 600000,
      authIS4TimeoutFactor: 0.25,
      authIS4AllowedUrls: 'https://localhost:48100',
      authAzureClientId: 'c75c766a-0da9-4710-b99e-96bf392ba7de',
      authAzureAuthority: 'https://login.microsoftonline.com/2659ef42-361c-4f17-9f8e-b2992668d9f3',
      authAzureScope: 'https://graph.microsoft.com',
      authAzureAllowedUrls: '/operatorsControls/',
      authAzureApi: 'User.Read',
      authKeycloakServer: 'http://127.0.0.1:48080',
      authKeycloakRealm: 'keycloak-example',
      authKeycloakClientId: 'keycloak-example-clientid',
      authKeycloakResourceId: 'keycloak-example-resourceid'
     };
  }
}

export class Configuration {
  config?: Provider;
}
