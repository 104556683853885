import { NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomePageComponent } from '../pages/home-page/home-page.component';
import { AppComponentsModule } from './components.module';
import { AuthJwtModule, AuthKeycloakModule } from 'angular-helpers';
import { AppMaterialModule } from 'apis-helpers';
import { CookieService } from 'ngx-cookie-service';
import { ListOperatorsPageComponent } from '../pages/list-operators-page/list-operators-page.component';
import { GpsPageComponent } from '../pages/gps-page/gps-page.component';

@NgModule({
  providers: [
    CookieService
  ],
  imports: [
    AppComponentsModule,
    AppMaterialModule,
    CommonModule,
    AuthJwtModule,
    AuthKeycloakModule
  ],
  declarations: [
    HomePageComponent,
    ListOperatorsPageComponent,
    GpsPageComponent,
  ]
})
export class PagesModule {}
