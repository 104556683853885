import { SchemaMetadata, Type } from '@angular/core';
import { ComponentFixture, TestBed, waitForAsync } from '@angular/core/testing';
import { HttpTestingController } from '@angular/common/http/testing';
import { TestingModule } from './testing.module';
import { TestsHelper } from '../../tests/tests.helper';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OAuthLogger } from 'angular-oauth2-oidc';

export interface TestComponentOptions {
  providers?: any[];
  declarations?: any[];
  imports?: any[];
  schemas?: Array<SchemaMetadata | any[]>;
  visible?: boolean;
  beforeEach?: any;
  shouldCreateExtension?: any;
}

export class Test<T> {
  public component: T;
  public fixture: ComponentFixture<T>;
  public debugElement: any;
  public http: HttpTestingController;
  // public config: Config;

  public initXMLHttpRequestTestingController() {
    beforeEach(waitForAsync(() => {
      spyOn(XMLHttpRequest.prototype, 'open').and.callFake((method: string, url: string): void => {
        console.log(`Call fake XMLHttpRequest.open and do nothing with parameters ${method} and ${url}`);
      });
      spyOn(XMLHttpRequest.prototype, 'send').and.callFake((body?: Document | BodyInit | null): void => {
        console.log(`Call fake XMLHttpRequest.send and do nothing with parameters ${body}`);
      });
    }));
  }

  public initHttpTestingController() {
    this.http = TestBed.inject(HttpTestingController);
  }

  public initConfig() {
    // this.config = TestBed.inject(Config);
  }

  public initPage(type: Type<T>, options?: TestComponentOptions) {
    if (!options) {
      options = {};
    }
    this.initComponent(type, options);
  }

  public initDialog(type: Type<T>, options?: TestComponentOptions) {
    if (!options) {
      options = {};
    }

    const providersForDialog = [
      { provide: MatDialogRef, useValue: {} },
      { provide: MAT_DIALOG_DATA, useValue: [] }
    ];

    options.providers = options.providers ?
        options.providers.concat(providersForDialog) : providersForDialog;

    this.initComponent(type, options);
  }

  public initComponent(type: Type<T>, options?: TestComponentOptions) {
    beforeEach(waitForAsync((): Promise<any> => {
      const p = TestBed.configureTestingModule({
        providers: (options && options.providers ? [ type, OAuthLogger ].concat(options.providers) : [ type, OAuthLogger ]),
        declarations: (options && options.declarations ? [ type ].concat(options.declarations) : [ type ]),
        imports: (options && options.imports ? [ TestingModule ].concat(options.imports) : [ TestingModule ]),
        schemas: (options ? options.schemas : undefined)
      })
      .compileComponents();
      return p;
    }));

    beforeEach(() => {
      this.fixture = TestBed.createComponent(type);
      this.component = this.fixture.componentInstance;
      this.debugElement = this.fixture.debugElement.componentInstance;

      if (options && options.beforeEach) {
        options.beforeEach();
      }

      if (options && options.visible === false) {
        TestsHelper.changeVisibility(this.fixture);
      }

      this.fixture.detectChanges();
    });

    it('should create', async () => {
      await expect(this.component).toBeTruthy();
      if (options && options.shouldCreateExtension) {
        options.shouldCreateExtension();
      }
    });
  }

  public initService(type: Type<T>, options?: TestComponentOptions) {
    beforeEach(waitForAsync((): Promise<any> => {
      const p = TestBed.configureTestingModule({
        providers: (options && options.providers ? [ type, OAuthLogger ].concat(options.providers) : [ type, OAuthLogger ]),
        declarations: (options ? options.declarations : undefined),
        imports: (options && options.imports ? [ TestingModule ].concat(options.imports) : [ TestingModule ]),
        schemas: (options ? options.schemas : undefined)
      })
      .compileComponents();
      return p;
    }));

    beforeEach(() => {
      this.component = TestBed.inject(type);
      if (options && options.beforeEach) {
        options.beforeEach();
      }
    });

    it('should create', async () => {
      await expect(this.component).toBeTruthy();
      if (options && options.shouldCreateExtension) {
        options.shouldCreateExtension();
      }
    });

    // ensure no request is outstanding
    afterEach(() => {
      if (this.http) {
        this.http.verify();
      }
    });
  }
}
