import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientTestingModule } from '@angular/common/http/testing';
import { RouterTestingModule } from '@angular/router/testing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { LoggerTestingModule, NGXLoggerMock } from 'ngx-logger/testing';
import { OAuthService, UrlHelperService } from 'angular-oauth2-oidc';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [],
  providers: [
    { provide: NGXLogger, useClass: NGXLoggerMock },
    OAuthService, UrlHelperService
  ],
  imports: [
    BrowserModule,
    LoggerTestingModule,
    BrowserAnimationsModule,
    HttpClientTestingModule,
    RouterTestingModule.withRoutes([]),
    FormsModule,
    ReactiveFormsModule,    
    ToastrModule.forRoot()
  ],
  exports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientTestingModule,
    RouterTestingModule,
    FormsModule,
    ReactiveFormsModule,
    LoggerTestingModule,
    ToastrModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class TestingModule { }
