
import { Routes, RouterModule, CanMatchFn, Router, Route } from '@angular/router';
import { NgModule, inject } from '@angular/core';
import { HomePageComponent } from '../pages/home-page/home-page.component';
import { ListOperatorsPageComponent } from '../pages/list-operators-page/list-operators-page.component';
import { GpsPageComponent } from '../pages/gps-page/gps-page.component';
import { MsalGuard } from '@azure/msal-angular';
import { AuthentificationService } from 'angular-helpers';
// export const canMatch = (reqRole: string, authService = inject(AuthentificationService)) => (authService.roles && authService.roles.includes(reqRole));

export const canMatch = (route: Route) => {
  let authservice = inject(AuthentificationService);
  // if no needed role to access this page 
  // or the user has needed role
  if (!route.data.neededRole || authservice.roles && authservice.roles.includes(route.data.neededRole)) {
    return true;
  } else {
    inject(Router).navigate(['/']);
    return false;
  }
}

export const routes: Routes = [
  { path: '',
    redirectTo: 'nav/home',
    pathMatch: 'full'
  },
  { 
    path: 'nav/home', 
    component: HomePageComponent, 
    canActivate: [MsalGuard],
    data: { 
      name: 'Dashboard', 
      link: 'nav/home', 
      svgIcon: 'home', 
      descriptionPage: "Dashboard"
    }
  },
  { 
    path: 'nav/operators',
    component: ListOperatorsPageComponent, 
    canActivate: [MsalGuard], 
    canMatch: [canMatch],
    data: { 
      name: 'Suivi inspecteur', 
      link: 'nav/operators', 
      svgIcon: 'users', 
      descriptionPage: "Liste des visites",
      neededRole: 'Admin'
    }
  },
  { 
    path: 'nav/round', 
    component: GpsPageComponent, 
    canActivate: [MsalGuard],
    data: { 
      name: 'Ma tournée', 
      link: 'nav/round',
      svgIcon: 'pin-gps', 
      descriptionPage: "Ma tournée"
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes, {
        enableTracing: true,
        onSameUrlNavigation: 'reload'
      }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
  static getRoutes() {
    return routes;
  }
}
