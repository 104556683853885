import { NGXLogger } from 'ngx-logger';
import PouchDB from 'pouchdb';
import { Observable } from 'rxjs';
import { EntityFromCouchDB } from './entity-from-couchdb.model';
import PouchdbValidation from 'pouchdb-validation';

export class SynchronizedPouchDB {
  private _typedDataAsObservables = new Map<string, Observable<EntityFromCouchDB[]>>();

  public constructor(public localDB: PouchDB, public remoteDB: PouchDB, private getUserCtx: () => Promise<any>, protected logger?: NGXLogger) {
    PouchDB.plugin(PouchdbValidation);
  }

  //#region CRUD
  public getAllTypedData<T extends EntityFromCouchDB>(splitDiscriminator: string): Promise<T[]> {
    return this.localDB.find({
      selector: {
        split_discriminator: splitDiscriminator
      }
    }).then(
      this.mapFindResultTo
    ).catch(error => {
      this.logger?.error(error);
    });
  }

  public getAllTypedData$<T extends EntityFromCouchDB>(splitDiscriminator: string): Observable<T[]> {
    let obs = this._typedDataAsObservables.get(splitDiscriminator) as Observable<T[]>;

    if (!obs) {
      obs = new Observable<T[]>((observer) => {
        let allData: T[];
        this.getAllTypedData<T>(splitDiscriminator).then(res => {
          allData = res;
          observer.next(allData);
        });
        this.onChange<T>((change) => {
          this.applyChangeOnArray(change, allData);
        }, splitDiscriminator);
      });
      this._typedDataAsObservables.set(splitDiscriminator, obs);
    }

    return obs;
  }

  public getAttachment(docID: string, fileName: string): Promise<File> {
    return this.localDB.getAttachment(docID, fileName);
  }

  public add<T extends EntityFromCouchDB>(data: T, id?: string) : Promise<any> {
    // Prefere to set manually _id (Guid.create().toString() by example)
    if (id) {
      data._id = id;
      return this.localDB.validatingPut(data, { userCtx: this.getUserCtx() });
    } else if (data._id) {
      return this.localDB.validatingPut(data, { userCtx: this.getUserCtx() });
    } else {
      return this.localDB.validatingPost(data, { userCtx: this.getUserCtx() });
    }
  }

  public addMultiple<T extends EntityFromCouchDB>(list: T[]) : Promise<any> {
    return this.localDB.validatingBulkDocs(list, { userCtx: this.getUserCtx() });
  }

  public update<T extends EntityFromCouchDB>(data: T) : Promise<any> {
    return this.localDB.validatingPut(data, { userCtx: this.getUserCtx() });
  }

  public updateMultiple<T extends EntityFromCouchDB>(list: T[]) : Promise<any> {
    return this.localDB.validatingBulkDocs(list, { userCtx: this.getUserCtx() });
  }

  public delete<T extends EntityFromCouchDB>(data: T) : Promise<any> {
    // Do not use remove to keep split_discriminator attribute (db.remove(exampleData._id, exampleData._rev);)
    // See https://pouchdb.com/api.html#filtered-replication for details
    return this.localDB.validatingPut({ _id: data._id, _rev: data._rev, split_discriminator: data.split_discriminator, _deleted: true }, { userCtx: this.getUserCtx() });
  }
  //#endregion CRUD


  /**
   * @remarks
   * Be careful with selector parameter and delete property, the document is not include and MangoQuery can tests document attributes
   * To work around the problem, we return all deleted documents regardless of their type or attributes.
   * And if an object no longer meets the condition of the MangoQuery then it is not returned and we do not know that it has been modified (and therefore that it no longer meets the condition).
   * In many cases, therefore, it is better to filter the data set.
   */
  public onChange<T extends EntityFromCouchDB>(bindedMethod: any, splitDiscriminator: string, options?: {
    selector?: any
  }) {
    options = options ?? {};
    const defaultSelector = {
      split_discriminator: splitDiscriminator
    };

    if (!options.selector) {
      options.selector = defaultSelector;
    } else {
      options.selector = {
        "$and": [
          options.selector,
          defaultSelector
        ]
      };
    }

    this.localDB.changes({
      since: 'now',
      live: true,
      include_docs: true,
      selector: options.selector
    }).on('change', (change) => {
      change.doc = this.mapTo<T>(change.doc);
      bindedMethod(change);
    });
  }

  private mapTo<T extends EntityFromCouchDB>(doc): T {
    const data = doc as T;
    return data;
  }

  private mapFindResultTo<T extends EntityFromCouchDB>(res): T[] {
    const data = res.docs.map((doc) => doc as T);
    return data;
  }

  public applyChangeOnArray<T extends EntityFromCouchDB>(change, array: T[]): T[] {
    const idx = array.findIndex(u => u._id === change.id);
    if (change.deleted) {
      // Remove data
      if (idx >= 0) {
        array.splice(idx, 1);
      }
    } else {
      if (idx >= 0) {
        // Update data
        array[idx] = this.mapTo<T>(change.doc);
      } else {
        // Add data
        array.push(this.mapTo<T>(change.doc));
      }
      return array;
    }
  }
}
