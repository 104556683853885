import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Unsubscriber } from '../../services/unsubscriber/unsubscriber.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { OperatorForm } from 'apis-helpers/models/operator-from-couchdb.model';
import { ReferenceDataFromCouchDB } from 'apis-helpers/models/reference-data-from-couchdb.model';
import moment from 'moment';
import { AuthentificationService, ImagesService } from 'angular-helpers';
import { CouchdbService } from '../../services/couchdb-database/couchdb.service';

export class OperatorDialogModel {
  constructor(public operator: OperatorForm) {}
}

const MAX_FILE_SIZE = 1280;

@Component({
  selector: 'app-operator-dialog',
  templateUrl: './operator-dialog.component.html',
  styleUrls: ['./operator-dialog.component.scss']
})
export class OperatorDialogComponent extends Unsubscriber implements OnInit {
  public loading = false;
  public operator: OperatorForm;
  public isEditMode = false;
  public operatorForm = new FormGroup({
    operatorName: new FormControl(null, [Validators.required]),
    date: new FormControl(null, [Validators.required]),
    comment: new FormControl(null),
    complianceStatus: new FormControl(null),
    choice: new FormControl(null),
    attachment: new FormControl(null)
  });
  @ViewChild('inputFile') inputFile: ElementRef;
  public choiceSelected: ReferenceDataFromCouchDB;
  public formSubmit = false;

  constructor(
    public dialogRef: MatDialogRef<OperatorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OperatorDialogModel,
    private toastr: ToastrService,
    public couchdbService: CouchdbService,
    private imagesService: ImagesService,
    private authService: AuthentificationService
  ) {
    super();
    if(data.operator !== undefined) {
      this.operator = data.operator;
      this.isEditMode = true;
    }
  }

  ngOnInit(): void {
    this.formSubmit = false;
    if(this.isEditMode) this.mapDBToForm();
  }

  //#region form
  public getErrorControl(controlName: string): boolean {
    return this.formSubmit && this.operatorForm.get(controlName).invalid;
  }
  public selectStatus(status: ReferenceDataFromCouchDB): void {
    this.operatorForm.get('complianceStatus').setValue(status);
  }

  public selectChoice(choice: ReferenceDataFromCouchDB): void {
    this.choiceSelected = choice;
  }

  public handleFileInput(files: FileList) {
    this.loading = true;
    // Accept only image or pdf file (not video : add control for mobile)
    if(files[0].type.includes('image') || files[0].type.includes('pdf')) {
      if(files[0].type.includes('image')) {
        this.imagesService.resizeImageFile(files[0], MAX_FILE_SIZE).then(fileResized => {
          if(fileResized.size > 0) {
            this.operatorForm.get('attachment').setValue(fileResized);
            this.toastr.show("Image importée avec succès : " + fileResized.name);
          }
          this.loading = false;
        });
      } else {
        this.operatorForm.get('attachment').setValue(files[0]);
        this.toastr.show("Pdf importé avec succès : " + files[0].name);
        this.loading = false;
      }
    } else {
      this.toastr.error("Le format de PJ importée n'est pas accepté.");
      this.loading = false;
    }
  }

  public downloadAttachment() {
    this.loading = true;
    let attachment = this.operatorForm.get('attachment').value;
    let url = window.URL.createObjectURL(attachment);
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = attachment.name;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    this.loading = false;
  }

  public deleteAttachment() {
    this.operatorForm.get('attachment').setValue(null);
  }
  //#endregion form

  //#region interact with database

  private mapDBToForm(): void {
    this.operatorForm.get('operatorName').setValue(this.operator.operatorName);
    this.operatorForm.get('date').setValue(this.operator.date);
    this.operatorForm.get('comment').setValue(this.operator.comment);
    this.operatorForm.get('complianceStatus').setValue(this.operator.complianceStatus);
    if(this.operator.choice) {
      this.operatorForm.get('choice').setValue(this.operator.choice._id);
      this.choiceSelected = this.operator.choice;
    }

    if(this.operator._attachments && Object.keys(this.operator._attachments)[0] != undefined) {
      let fileName = Object.keys(this.operator._attachments)[0];
      this.couchdbService.getAttachmentOperatorForm(this.operator._id, fileName)
        .then(
          (file) => {
            var fileForm = Object.assign(file);
            if(!fileForm.name) fileForm.name = fileName;
            this.operatorForm.get('attachment').setValue(fileForm);
          }
        )
        .catch(() => this.toastr.error("Erreur lors de la récupération de la PJ"));
    }
  }

  private mapFormToDB(): OperatorForm {
    var newOperator = this.isEditMode
      ? this.operator
      : new OperatorForm();
    newOperator.idInspector = this.authService.sub;
    newOperator.operatorName = this.operatorForm.get('operatorName').value;
    newOperator.date = moment(this.operatorForm.get('date').value).format("YYYY-MM-DD[T]HH:mm:ss");
    newOperator.comment = this.operatorForm.get('comment').value;
    newOperator.complianceStatus = this.operatorForm.get('complianceStatus').value;
    newOperator.choice = this.choiceSelected;
    newOperator._attachments = this.getAttachments();

    return newOperator;
  }

  private syncData(): void {
    this.loading = true;
    if(this.operatorForm.valid) {
      let operatorToPush = this.mapFormToDB();
        this.isEditMode
          ? this.couchdbService.updateOperatorForm(operatorToPush)
          : this.couchdbService.addOperatorForm(operatorToPush);

        this.dialogRef.close(true);
        this.loading = false;
    } else {
      this.toastr.error("Veuillez corriger les erreurs du formulaire");
      this.formSubmit = true;
      this.loading = false;
    }
  }
  //#endregion interact with database

  //#region dialog
  public onValidate(): void {
    this.syncData();
  }

  public onDismiss(): void {
    this.dialogRef.close(false);
  }
  //#endregion dialog

  private getAttachments(): JSON {
    var files = {};

    let attachmentFromForm = this.operatorForm.get('attachment').value;
    if(attachmentFromForm !== null) {
      files[attachmentFromForm.name] = {
        content_type: attachmentFromForm.type,
        data: attachmentFromForm
      }
    }

    return <JSON>files;
  }
}

