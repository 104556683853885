import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { ConfigurationProvider } from '../../config';
import { ApiMessage, createApiMessageInstance } from '../../models/api-message.model';
import { map, shareReplay } from 'rxjs/operators';
import { UntypedFormGroup } from '@angular/forms';
import { Constructor } from '../../helpers/constructor';

@Injectable({
  providedIn: 'root',
})
export class HttpClientExtended {

  constructor(
    protected http: HttpClient, 
    protected config: ConfigurationProvider,
    protected logger: NGXLogger) { }

  public mapFromServer<T extends ApiMessage>(c: Constructor<T>) {
    return map((json: T) => { 
      if (json) {
        return createApiMessageInstance(c).loadFromJson(json);
      } else {
        return undefined;
      }
    });
  }

  public mapArrayFromServer<T extends ApiMessage>(c: Constructor<T>) {
    return map((jsonList: T[]) => { 
      if (jsonList) {
        return jsonList.map(jsonItem => createApiMessageInstance(c).loadFromJson(jsonItem));
      } else {
        return undefined;
      }
    });
  }
  

  public get(url: string, options?: any): Observable<object> {
    return this.http.get(url, options);
  }

  public getData<T extends ApiMessage>(c: Constructor<T>, url: string, options?: any): Observable<T> {
    const obsResult$ = this.get(url, options);
    return obsResult$.pipe(
      this.mapFromServer(c)
    );
  }
    
  public getMultipleData<T extends ApiMessage>(c: Constructor<T>, 
                                                url: string, 
                                                withShareReplay: boolean = true, 
                                                options?: any): Observable<T[]> {
    const obsResult$ = this.get(url, options);
    let returnValue$ =  obsResult$.pipe(
      this.mapArrayFromServer(c)
    );
    if (withShareReplay) {
      returnValue$ = returnValue$.pipe(
        shareReplay({
          bufferSize: 1,
          refCount: true
        })
      );
    }
    return returnValue$;
  }


  public post(url: string, data?: any): Observable<object> {
    if (data instanceof FormData) {
      return this.http.post(url, data);
    } else {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      const dataSerialized = JSON.stringify(data);
      return this.http.post(url, dataSerialized, {headers: headers});
    }
  }

  public postData<T extends ApiMessage>(c: Constructor<T>, url: string, data?: T | any): Observable<T> {
    let param = data;
    if (param instanceof ApiMessage) {
      param = param?.mapForServer();
    }
    const obsResult$ = this.post(url, param);
    return obsResult$.pipe(
      this.mapFromServer(c)
    );    
  }

  public postMultipleData<T extends ApiMessage>(c: Constructor<T>, url: string, data?: T[] | any): Observable<T[]> {
    let param = data;
    if (param && Array.isArray(param) && param.length > 0) {
      if (param[0] instanceof ApiMessage) {
        param = param.map(d => d.mapForServer());
      }
    }
    const obsResult$ = this.post(url, param);
    return obsResult$.pipe(
      this.mapArrayFromServer(c)
    );    
  }

  public postFiles(url: string, files: File[], params?: any): Observable<any> {
    const formData: FormData = new FormData();
    files.forEach(file => {
        formData.append('files', file, file.name);
    });


    if (params) {
      if (params instanceof UntypedFormGroup) {
        params = params.value;
      }
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          let value = params[key];
          if (value instanceof Date) {
            value.setMinutes(value.getMinutes() - value.getTimezoneOffset());
            value = value.toJSON();
          } else if (value && value._isAMomentObject) {
            value = value.toJSON();
          }
          if (value === null) {
            value = '';
          }
          formData.append(key, value);  
        }
      }
    }

    return this.http.post(url, formData);
  }

  public postMultiParts(url: string, params?: any, addParts?: (FormData) => void): Observable<any> {
    const formData: FormData = new FormData();
    if (params) {
      if (params instanceof UntypedFormGroup) {
        params = params.value;
      }
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          let value = params[key];
          if (value instanceof Date) {
            value.setMinutes(value.getMinutes() - value.getTimezoneOffset());
            value = value.toJSON();
          } else if (value && value._isAMomentObject) {
            value = value.toJSON();
          }
          if (value === null) {
            value = '';
          }
          formData.append(key, value);  
        }
      }
    }
    if (addParts) {
      addParts(formData);
    }

    return this.http.post(url, formData);
  }

  public postMultiPartsWithFiles(url: string, filesKey: string = 'files', files: File[], params?: any): Observable<any> {
    return this.postMultiParts(url, params, (formData: FormData) => {
      files.forEach(file => {
        formData.append(filesKey, file, file.name);
      });
    });
  }
  
  public delete(url: string): Observable<object> {
    return this.http.delete(url);
  }

  public deleteData<T extends ApiMessage>(c: Constructor<T>, url: string): Observable<T> {
    const obsResult$ = this.delete(url);
    return obsResult$.pipe(
      this.mapFromServer(c)
    );    
  }

  public deleteMultipleData<T extends ApiMessage>(c: Constructor<T>, url: string): Observable<T[]> {
    const obsResult$ = this.delete(url);
    return obsResult$.pipe(
      this.mapArrayFromServer(c)
    );    
  }

  public getFromAuthApi(path: string, options?: any): Observable<any> {
    const suffix = (this.config.params.authJwtApiOnGateway) ? '' : '/api';
    return this.get(this.config.params.authJwtApi + suffix + path, options);
  }

  public postToAuthApi(path: string, data?: any): Observable<object> {
    const suffix = (this.config.params.authJwtApiOnGateway) ? '' : '/api';
    return this.post(this.config.params.authJwtApi + suffix + path, data);
  }
}
