import { Injectable } from '@angular/core';
import configForTest from './config.json';
import configEnvForTest from './config.test.json';
import { ConfigService } from 'angular-helpers';
import { ApisConfigurationInterface } from 'apis-helpers';

@Injectable({ providedIn: 'root' })
export class Config extends ConfigService {

  static params: ApisConfigurationInterface;
  public params: ApisConfigurationInterface;
  public paramsPromise: Promise<ApisConfigurationInterface>;

  configUrl = 'configs/config.json';
  configEnvUrl = 'configs/config.env.json';

  static loadConfigForTest() {
    ConfigService.params = Object.assign({}, configForTest, configEnvForTest);
  }

  static apiUseGateway(api: string): boolean {
    return api.startsWith(this.params.gatewayApi);
  }

  static getApiSuffix(api: string): string {
    return this.apiUseGateway(api) ? '' : '/api';
  }

  static getApiUrl(api: string, path?: string): string {
    let url = api + Config.getApiSuffix(api) + '/';
    if (path) {
      url += path;
    }
    return url;
  }

  static getHubSuffix(api: string): string {
    return this.apiUseGateway(api) ? 'Hub' : '';
  }

  static loadConfig(callback: (config: ApisConfigurationInterface) => void): Promise<void> {
    return super.loadConfig(callback);
  }

  public loadConfig(): Promise<ApisConfigurationInterface> {
    return <Promise<ApisConfigurationInterface>> super.loadConfig();
  }
}
