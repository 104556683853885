import { By } from '@angular/platform-browser';
import { DebugElement, Type } from '@angular/core';
import { OAuthLogger } from 'angular-oauth2-oidc';
import { TestBed } from '@angular/core/testing';
import { TestingModule } from './testing.module';
import { Test, TestComponentOptions } from './test';
import { TestsHelper } from '../../tests/tests.helper';

export class TestDirective<TDirective, TComponent> extends Test<TComponent> {
  public debugDirective: DebugElement[] = [];
  public directive: TDirective;

  public initDirective(directiveType: Type<TDirective>, testedType: Type<TComponent>, options?: TestComponentOptions) {
    beforeEach(() => {
      TestBed.resetTestingModule();
      TestBed.configureTestingModule({
        providers: (options && options.providers ? [ OAuthLogger ].concat(options.providers) : [ OAuthLogger ]),
        declarations: (options && options.declarations
           ? [ directiveType, testedType ].concat(options.declarations)
           : [directiveType, testedType]),
        imports: (options && options.imports ? [ TestingModule ].concat(options.imports) : [ TestingModule ]),
        schemas: (options ? options.schemas : undefined)
      });
    });

    beforeEach(() => {
      this.fixture = TestBed.createComponent(testedType);
      this.component = this.fixture.componentInstance;
      this.debugElement = this.fixture.debugElement.componentInstance;

      if (options && options.beforeEach) {
        options.beforeEach();
      }

      if (options && !options.visible) {
        TestsHelper.changeVisibility(this.fixture);
      }

      this.fixture.detectChanges();

      return this.fixture.whenStable().then(() => {
        this.fixture.detectChanges();
        this.debugDirective = this.fixture.debugElement.queryAll(By.directive(directiveType));
        this.directive = this.debugDirective[0].injector.get(directiveType) as TDirective;
      });
    });

    it('can instantiate it', () => expect(this.component).not.toBeNull());

    it('should have click-outside directive', async () => {
      await expect(this.debugDirective.length).toBeGreaterThan(0);
    });

    // ensure no request is outstanding
    afterEach(() => {
      if (this.http) {
        this.http.verify();
      }
    });
  }
}
