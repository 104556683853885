import { Constructor } from "../helpers/constructor";

export abstract class ApiMessage {
  public fromHub = false;
  loadFromJson(json: object): this {
    Object.keys(json).forEach(key => {
      if (this.hasOwnProperty(key)) {
        this[key] = json[key];
      } else {
        let _this = this;
        Object.keys(this).filter(function (k) {
          if (k.toLowerCase() === key.toLowerCase()) {
            _this[k] = json[key];
          }
        });
      }
    });
    return this;
  }
  mapForServer(): object {
    return this as object;
  }
  public constructor() {}
}

export function createApiMessageInstance<T extends ApiMessage>(c: Constructor<T>): T {
  return new c();
}
