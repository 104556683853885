import { Component, Input, NgZone, OnInit, ViewChild } from "@angular/core";
import { ControlContainer, UntypedFormGroup } from "@angular/forms";
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from "rxjs";

@Component({
  selector: "frmk-textarea-field",
  templateUrl: "./textarea-field.component.html",
  styleUrls: ["./textarea-field.component.scss"],
})
export class TextAreaFieldComponent implements OnInit {
  formGroup: UntypedFormGroup;
  @Input() label: string;
  @Input() minRows: number = 1;
  @Input() maxRows: number = 5;
  @Input() controlName: string;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  constructor(private _ngZone: NgZone, private controlContainer: ControlContainer) {}

  get control() { 
    return this.formGroup.get(this.controlName);
  }

  ngOnInit() {
    this.formGroup = <UntypedFormGroup>this.controlContainer.control;
  }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }
}
