import { Injectable, ViewContainerRef } from '@angular/core';
import { lastValueFrom, map, Observable, of } from 'rxjs';
import { ConfigurationProvider } from '../../config/config.provider';
import { KeycloakTokenService } from '../../auth-keycloak/services/auth-keycloak-init.service';
import { AuthJwtService } from '../../auth-jwt/services/auth-jwt.service';
import { AuthIS4Service } from '../../auth-is4/services/auth-is4.service';
import { LoginJwtDialogComponent } from '../../auth-jwt/dialogs/login-jwt-dialog/login-jwt-dialog.component';
import { AuthAzureService } from '../../auth-azure';

@Injectable({
  providedIn: 'root'
})
export class AuthentificationService {

  constructor(
    private configService: ConfigurationProvider,
    private authKeycloakTokenService: KeycloakTokenService,
    private authJwtService: AuthJwtService,
    private authIS4Service: AuthIS4Service,
    private authAzureService: AuthAzureService) {

  }

  public get keycloakIsConfiguredOnServer(): boolean {
    if (this.configService.params.authKeycloakServer) {
      return true;
    } else {
      return false;
    }
  }

  public get jwtIsConfiguredOnServer(): boolean {
    if (this.configService.params.authJwtApi) {
      return true;
    } else {
      return false;
    }
  }

  public get is4IsConfiguredOnServer(): boolean {
    if (this.configService.params.authIS4Api) {
      return true;
    } else {
      return false;
    }
  }

  public get azureAdIsConfiguredOnServer(): boolean {
    if (this.configService.params.authAzureApi) {
      return true;
    } else {
      return false;
    }
  }

  public get authType(): string {
    if (this.keycloakIsConfiguredOnServer) {
      return 'Keycloak';
    } else if (this.jwtIsConfiguredOnServer) {
      return 'Jwt';
    } else if (this.is4IsConfiguredOnServer) {
      return 'IS4';
    } else if (this.azureAdIsConfiguredOnServer) {
      return 'Azure AD';
    }
  }

  public get authIsConfiguredOnServer(): boolean {
    return this.keycloakIsConfiguredOnServer || this.jwtIsConfiguredOnServer || this.is4IsConfiguredOnServer || this.azureAdIsConfiguredOnServer;
  }

  public get hasBearerToken(): boolean {
    if (this.keycloakIsConfiguredOnServer) {
      return this.authKeycloakTokenService.hasToken;
    } else if (this.jwtIsConfiguredOnServer) {
      return this.authJwtService.hasToken();
    } else if (this.is4IsConfiguredOnServer) {
      throw new Error('Not implemented');
    } else if (this.azureAdIsConfiguredOnServer) {
      return this.authAzureService?.getActiveAccount()?.idToken !== undefined;
    }
  }

  public get isTokenExpired(): boolean {
    if (this.keycloakIsConfiguredOnServer) {
      return this.authKeycloakTokenService.isTokenExpired();
    } else if (this.jwtIsConfiguredOnServer) {
      return this.authJwtService.isTokenExpired();
    } else if (this.is4IsConfiguredOnServer) {
      throw new Error('Not implemented');
    } else if (this.azureAdIsConfiguredOnServer) {
      throw new Error('Not implemented');
    }
  }

  public get refreshTokenPromise(): Promise<any> {
    if (this.keycloakIsConfiguredOnServer) {
      return new Promise(function(resolve) {
        this.authKeycloakTokenService.refreshToken;
     });
    } else if (this.jwtIsConfiguredOnServer) {
      return this.authJwtService.refreshTokenPromise();
    } else if (this.is4IsConfiguredOnServer) {
      return new Promise(function(resolve) {
        this.authIS4Service.refreshToken;
     });
    } else if (this.azureAdIsConfiguredOnServer) {
      // this.authAzureService.refreshTokenPromise();
    }
  }

  public get currentToken(): string {
    if (this.keycloakIsConfiguredOnServer) {
      return this.authKeycloakTokenService.currentAccessToken;
    } else if (this.jwtIsConfiguredOnServer) {
      return this.authJwtService.getJwtToken();
    } else if (this.is4IsConfiguredOnServer) {
      return this.authIS4Service.accessToken;
    } else if (this.azureAdIsConfiguredOnServer) {
      return this.authAzureService?.getActiveAccount()?.idToken;
    }
  }


  public get authServerUrl(): string {
    if (this.keycloakIsConfiguredOnServer) {
      return this.configService.params.authKeycloakServer;
    } else if (this.jwtIsConfiguredOnServer) {
      return this.configService.params.authJwtApi;
    } else if (this.is4IsConfiguredOnServer) {
      return this.configService.params.authIS4Api;
    } else if (this.azureAdIsConfiguredOnServer) {
      return this.configService.params.authAzureApi;
    }
  }

  public get isAuthenticated$(): Observable<boolean> {
    if (this.keycloakIsConfiguredOnServer) {
      return this.authKeycloakTokenService.isAuthenticated$;
    } else if (this.jwtIsConfiguredOnServer) {
      return this.authJwtService.isLoggedIn();
    } else if (this.is4IsConfiguredOnServer) {
      return this.authIS4Service.isAuthenticated$;
    } else if (this.azureAdIsConfiguredOnServer) {
      return this.authAzureService.isAuthenticated$;
    }
  }

  public get username(): string | undefined {
    if (this.keycloakIsConfiguredOnServer) {
      return this.authKeycloakTokenService.username;
    } else if (this.jwtIsConfiguredOnServer) {
      return this.authJwtService.getJwtTokenData().login;
    } else if (this.is4IsConfiguredOnServer) {
      const identityClaims: any = this.authIS4Service.identityClaims;
      return identityClaims?.preferred_username;
    } else if (this.azureAdIsConfiguredOnServer) {
      return this.authAzureService.getActiveAccount().name ?? this.authAzureService.getActiveAccount().username;
    }
  }

  public get sub(): string | undefined {
    if (this.keycloakIsConfiguredOnServer) {
      throw new Error('Not implemented');
    } else if (this.jwtIsConfiguredOnServer) {
      throw new Error('Not implemented');
    } else if (this.is4IsConfiguredOnServer) {
      throw new Error('Not implemented');
    } else if (this.azureAdIsConfiguredOnServer) {
      return this.authAzureService.getActiveAccount()?.idTokenClaims?.sub;
    }
  }

  public get roles(): string[] {
    if (this.keycloakIsConfiguredOnServer) {
      return []; // TODO
    } else if (this.jwtIsConfiguredOnServer) {
      return this.authJwtService.getJwtTokenData().roles;
    } else if (this.is4IsConfiguredOnServer) {
      return []; // TODO
    } else if (this.azureAdIsConfiguredOnServer) {
      return this.authAzureService.getRoles();
    }
  }

  public get accessToken(): Observable<string> {
    if (this.keycloakIsConfiguredOnServer) {
      return this.authKeycloakTokenService.getAccessToken();
    } else if (this.jwtIsConfiguredOnServer) {
      return this.authJwtService.getJwtToken$().pipe(map(t => t.token));
    } else if (this.is4IsConfiguredOnServer) {
      throw new Error('Not implemented');
    } else if (this.azureAdIsConfiguredOnServer) {     
      return this.authAzureService.getToken();
    }
  }

  public login() {
    if (this.keycloakIsConfiguredOnServer) {
      this.authKeycloakTokenService.login();
    } else if (this.jwtIsConfiguredOnServer) {
      this.authJwtService.openLoginDialog(LoginJwtDialogComponent);
    } else if (this.is4IsConfiguredOnServer) {
      this.authIS4Service.login();
    } else if (this.azureAdIsConfiguredOnServer) {
      this.authAzureService.login();
    }
  }

  public logout() {
    if (this.keycloakIsConfiguredOnServer) {
      this.authKeycloakTokenService.logout();
    } else if (this.jwtIsConfiguredOnServer) {
      this.authJwtService.logout().subscribe();
    } else if (this.is4IsConfiguredOnServer) {
      this.authIS4Service.logout();
    } else if (this.azureAdIsConfiguredOnServer) {
      this.authAzureService.logout();
    }
  }

}
