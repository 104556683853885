import { KeycloakTokenService } from './services/auth-keycloak-init.service';
import { NgModule } from "@angular/core";
import { ConfigurationProvider } from '../config';

@NgModule({
    providers: [
      { provide: 'IAuthKeycloakTokenService', useClass: KeycloakTokenService }
    ]
})
export class AuthKeycloakModule { }

export function resolveAuthKeycloakConfig(configService: ConfigurationProvider, authKeycloakInit: KeycloakTokenService) {
  if (configService.params.authKeycloakClientId) {
    authKeycloakInit.setConfigParams(configService.params);
    authKeycloakInit.init();
  }
}
