import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { ConfigurationProvider } from '../config';
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(configService: ConfigurationProvider): IPublicClientApplication {

  const baseUrl = configService.params.baseUrl;

  const array = baseUrl.split('/');

  var pathPrefix = "/";

  if (array.length > 3)
  {
    for (let i = 3; i < array.length; i++) // add all pathprefix
    {
      pathPrefix += "/" + array[i];
    }

    pathPrefix = pathPrefix.substring(1); // remove first character
  }

  return new PublicClientApplication({
    auth: {
      clientId: configService.params.authAzureClientId,
      authority: configService.params.authAzureAuthority,
      redirectUri: pathPrefix,
      postLogoutRedirectUri: pathPrefix
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(configService: ConfigurationProvider): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(configService.params.authAzureApi, configService.params.authAzureScope.split(' '));
  const allowedUrls = configService.params.authAzureAllowedUrls.split(' ');
  for (let index = 0; index < allowedUrls.length; index++) {
    const url = allowedUrls[index];
    if (typeof url!='undefined' && url) {
      protectedResourceMap.set(url, configService.params.authAzureScope.split(" "));
    }
  }

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(configService: ConfigurationProvider): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...configService.params.authAzureScope.split(' ')]
    },
    loginFailedRoute: '/nav/login'
  };
}
