import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ConfigurationProvider } from '../../config/config.provider';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { IAuthAzureService } from '../interfaces/auth-azure.interface';
import { AccountInfo, EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root'
})
export class AuthAzureService implements IAuthAzureService, OnDestroy {
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private config: ConfigurationProvider,
    public dialog: MatDialog,
    private msalBroadcastService: MsalBroadcastService,
    private authService: MsalService) {
      if (navigator.onLine) {
        this.initAzureAdService();
      }
  }

  private initAzureAdService() {
    // this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
    ).subscribe((result: EventMessage) => {
      if (this.authService.instance.getAllAccounts().length === 0) {
        window.location.pathname = "/";
      }
    });

    this.msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    ).subscribe((r) => {
      this.checkAndSetActiveAccount();
      this.isLoggedIn();
    });
  }

  getRoles(): string[] {
    return this.getActiveAccount()?.idTokenClaims.roles;
  }

  getActiveAccount(): AccountInfo {
    return this.authService.instance.getActiveAccount();
  }

  isLoggedIn() {
    if (this.authService.instance.getAllAccounts().length > 0) {
      this.isAuthenticatedSubject$.next(true);
    } else {
      this.isAuthenticatedSubject$.next(false);
    }
  }

  getToken(): Observable<string> {
    return new Observable<string>(o => {
      const accessTokenRequest = {scopes: this.config.params.authAzureScope.split(' ')};
      this.authService.instance
        .acquireTokenSilent(accessTokenRequest)
        .then(function (accessTokenResponse) {
          // Acquire token silent success
          o.next(accessTokenResponse.accessToken);
        }).catch(error => {
         o.error(error);
        });
    })
  }

  private checkAndSetActiveAccount(): void{
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  isConfigured(): boolean {
    if (this.config.params?.authAzureApi) {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  async login() {
    if (this.authService.instance.getAllAccounts().length === 0) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }

  logout() {
    this.authService.logoutRedirect();
  }
}
