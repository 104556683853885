import { Injectable } from '@angular/core';
import { HealthCheckService, HealthCheck } from 'angular-helpers';
import { ApisConfigurationProvider } from '../../config';
import { ApisHttpClientExtended } from '../apis-http-client-extended/apis-http-client-extended.service';

@Injectable({
  providedIn: 'root'
})
export class ApisHealthCheckService extends HealthCheckService {

  constructor(
    protected http: ApisHttpClientExtended, protected config: ApisConfigurationProvider) {
    super(http);
  }

  public healthChecks(): Promise<HealthCheck> {
    const urls = [
      this.config.params.authJwtApi,
      this.config.params.authIS4Api,
      this.config.params.gatewayApi,
      this.config.params.exampleApi,
      this.config.params.logsApi,
      this.config.params.messagingApi,
    ];

    return super.healthChecks(urls);
  }

}
