import { NgModule} from '@angular/core';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { RouterModule } from '@angular/router';

import { PageComponent } from '../components/page/page.component';
import { ApisDirectivesModule, AppMaterialModule } from 'apis-helpers';
import { ComponentsModule } from 'angular-helpers';
import { AuthAzureModule } from 'angular-helpers/auth-azure';
import { BottomNavComponent } from '../components/bottom-nav/bottom-nav.component';
import { RadioButtonComponent } from '../components/radio-button/radio-button.component';
import { PipesModule } from '../pipes/pipes.module';
import { LoaderComponent } from '../components/loader.component';
import { LeafletMapComponent } from '../components/leaflet-map/leaflet-map.component';

@NgModule({
  imports: [
    AppMaterialModule,
    ScrollingModule,
    RouterModule,
    ApisDirectivesModule,
    ComponentsModule,
    AuthAzureModule,
    PipesModule.forRoot()
  ],
  declarations: [
    PageComponent,
    BottomNavComponent,
    RadioButtonComponent,
    LoaderComponent,
    LeafletMapComponent
  ],
  exports: [
    PageComponent,
    ApisDirectivesModule,
    ComponentsModule,
    BottomNavComponent,
    RadioButtonComponent,
    LoaderComponent,
    LeafletMapComponent
  ]
})
export class AppComponentsModule {}
