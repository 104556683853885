import { Entity } from "./entity.model";

export abstract class EntityWithKey<T extends number | string> extends Entity {
  abstract GetKey(): T;

  public GetTextValue(): string {
    if (this['typeMessage']) {
      return `${this['typeMessage']} (${this.GetKey().toString()})`;
    } else {
      return this.GetKey().toString();
    }
  }

  public constructor() {
    super();
  }
}
