import { EventEmitter } from '@angular/core';
import { MonoTypeOperatorFunction, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { createApiMessageInstance, EntityWithKey, RealtimeEntitiesEvent } from '../../models';
import { NGXLogger } from 'ngx-logger';
import { HttpClientExtended } from '../http-extended/http-client-extended.service';
import { HubConnection } from '@microsoft/signalr';
import { HubsManagmentService } from '../hubs-managment/hubs-managment.service';
import { ControllerFromApi } from '../controller-from-api/controller-from-api.service';
import { UpdatableGetterInterface } from '../../interfaces/updatable-getter.interface';
import { Constructor } from '../../helpers/constructor';

export abstract class GetterRelation1N<TEntity extends EntityWithKey<TEKey>, 
                        TEKey extends number | string,
                        TRelation extends EntityWithKey<TRKey>,
                        TRKey extends number | string> 
                        extends ControllerFromApi
                        implements UpdatableGetterInterface<TRelation, TRKey> {
  realtimeHubConnection: HubConnection;
  private cache: TRelation[] = [];

  public beforeRealtimeEvent: EventEmitter<RealtimeEntitiesEvent<TRelation, TRKey>> = new EventEmitter(false);
  public beforeRealtimeAddedEvent: EventEmitter<RealtimeEntitiesEvent<TRelation, TRKey>> = new EventEmitter(false);
  public beforeRealtimeUpdatedEvent: EventEmitter<RealtimeEntitiesEvent<TRelation, TRKey>> = new EventEmitter(false);
  public beforeRealtimeRemovedEvent: EventEmitter<RealtimeEntitiesEvent<TRelation, TRKey>> = new EventEmitter(false);

  public realtimeEvent: EventEmitter<RealtimeEntitiesEvent<TRelation, TRKey>> = new EventEmitter();
  public realtimeAddedEvent: EventEmitter<RealtimeEntitiesEvent<TRelation, TRKey>> = new EventEmitter();
  public realtimeUpdatedEvent: EventEmitter<RealtimeEntitiesEvent<TRelation, TRKey>> = new EventEmitter();
  public realtimeRemovedEvent: EventEmitter<RealtimeEntitiesEvent<TRelation, TRKey>> = new EventEmitter();

  abstract getRelationsFromEntity(data: TEntity): TRelation[];
  abstract setRelationsFromEntity(data: TEntity, relations: TRelation[]);
  abstract getReferenceKey(relation: TRelation): TEKey;

  protected getRelationFromEntity(data: TEntity, key: TRKey) {
    const relationsOfEntity = this.getRelationsFromEntity(data);
    if (relationsOfEntity) {
      const idx = relationsOfEntity.findIndex(r => r.GetKey() === key);
      if (idx >= 0) {
        return relationsOfEntity[idx];
      }
    }
  }

  constructor(protected http: HttpClientExtended, protected logger: NGXLogger, 
              protected updatableServiceOfEntity: UpdatableGetterInterface<TEntity, TEKey>, protected hubsManagment: HubsManagmentService,
              protected controllerBaseUrl: string, protected constructorType: Constructor<TRelation>) { 
    super(http, logger, controllerBaseUrl);
  }

  protected initRealtimeEntities(hubBaseUrl: string, typeMessage?: string, realtimeHubConnectionWithAuth: boolean = false) {
    if (!typeMessage) {
      const dummyInstance = createApiMessageInstance(this.constructorType);
      typeMessage = dummyInstance.constructor.name;
    }

    if (hubBaseUrl) {
      if (realtimeHubConnectionWithAuth) {
        this.realtimeHubConnection = this.hubsManagment.connectHub(hubBaseUrl + 'RealtimeEntitiesWithAuthHub', true);
      } else {
        this.realtimeHubConnection = this.hubsManagment.connectHub(hubBaseUrl + 'RealtimeEntitiesHub', false);
      }
      this.realtimeHubConnection.on('RealtimeEvent', (json: any) => {
        if (json["obj"]["typeMessage"] === typeMessage) {
          this.onRealtimeEvent(json);
          const realtimeEvent = new RealtimeEntitiesEvent<TRelation, TRKey>(this.constructorType, json);
          this.onRealtimeEventRelation(realtimeEvent);
        }
      });
    }
  }

  protected isAttributeToCopy(key: string) {
    return (key !== undefined);
  }

  protected getUrlFromControllerWithReference(referenceEntityKey: TEKey, subPath?: string, queryParams?: string | any): string {
    const path = this.controllerBaseUrl?.toString().replace('{referenceKey}', referenceEntityKey?.toString());
    return this.getUrlFromController(subPath, queryParams, path);
  }

  protected onRealtimeEvent(json: any) {
    if (json.eventType && json.obj.typeMessage) {
      this.logger.info(`onRealtimeEvent(${json.eventType}, ${json.obj.typeMessage})`);
    } else {
      this.logger.info(`onRealtimeEvent(${json})`);
    }
  }

  protected onRealtimeEventRelation(realtimeEvent: RealtimeEntitiesEvent<TRelation, TRKey>) {
    if (realtimeEvent) {
      const relation = realtimeEvent.obj;
      const referenceKey = this.getReferenceKey(relation);
      
      this.beforeRealtimeEvent.emit(realtimeEvent);
      switch (realtimeEvent.eventType) {
        case "Added":
          this.beforeRealtimeAddedEvent.emit(realtimeEvent);
          this.updatableServiceOfEntity?.getDataForUpdate(referenceKey, (data) => {
            this.refreshRelationInternal(data, relation);
          });
          this.realtimeAddedEvent.emit(realtimeEvent);
          break;
        case "Updated":
          this.beforeRealtimeUpdatedEvent.emit(realtimeEvent);
          this.updatableServiceOfEntity?.getDataForUpdate(referenceKey, (data) => {
            this.refreshRelationInternal(data, relation);
          });
          this.realtimeUpdatedEvent.emit(realtimeEvent);
          break;
        case "Removed":
          this.beforeRealtimeRemovedEvent.emit(realtimeEvent);
          this.updatableServiceOfEntity?.getDataForUpdate(referenceKey, (data) => {
            this.refreshDeletedRelationInternal(data, relation);
          });
          this.realtimeRemovedEvent.emit(realtimeEvent);
          break;
      }
      this.realtimeEvent.emit(realtimeEvent);
    }
  }

  //#region UpdatableGetterInterface
  // Get all data from parent cache
  public getAllDataInCache(call: (data: TRelation) => void) {
    this.updatableServiceOfEntity?.getAllDataInCache((data: TEntity) => {
      const relationsOfEntity = this.getRelationsFromEntity(data);
      relationsOfEntity?.forEach(call);
    });
  }

  public getReferencesDataInCache(referenceEntityKey: TEKey, call: (data: TEntity) => void) {
    this.updatableServiceOfEntity?.getAllDataInCache((data: TEntity) => {
      if (data.GetKey() == referenceEntityKey) {
        call(data);
      }
    });
  }

  // Get relation from all parent cache
  public getDataForUpdate(relationKey: TRKey, updateCall: (relation: TRelation) => void) {
    this.updatableServiceOfEntity?.getAllDataInCache((data: TEntity) => {
      const relationOfEntity = this.getRelationFromEntity(data, relationKey);
      if (relationOfEntity) {
        updateCall(relationOfEntity);
      }
    });
  }
  //#endregion

  // #region Internal refresh methods
  // Internal refresh of relation (create or update)
  protected copyAttributes(from: TRelation, to: TRelation) {
    this.logger.debug(`CopyAttributes from ${from.GetTextValue()} to ${to.GetTextValue()})`);
    Object.keys(from).forEach(key => {
      if (this.isAttributeToCopy(key) && to.hasOwnProperty(key)) {
        if (to[key] instanceof Array) {
          if (from[key] !== null && from[key] !== undefined) {
            to[key] = from[key];
          }
        } else {
          to[key] = from[key];
        }        
      }
    });
  }

  protected refreshRelationInternal(data: TEntity, relation: TRelation) {
    const relationsOfEntity = this.getRelationsFromEntity(data);
    if (relationsOfEntity) {
      const idx = relationsOfEntity.findIndex(r => r.GetKey() === relation.GetKey());
      if (idx >= 0) {
        // relationsOfEntity[idx] = relation;
        this.copyAttributes(relation, relationsOfEntity[idx]);
      } else {
        relationsOfEntity.push(relation);
      }
    }
  }

  // Internal refresh of relation (delete)
  protected refreshDeletedRelationInternal(data: TEntity, deletedRelation: TRelation) {
    const relationsOfEntity = this.getRelationsFromEntity(data);
    if (relationsOfEntity) {
      const idx = relationsOfEntity.findIndex(r => r.GetKey() === deletedRelation.GetKey());
      if (idx >= 0) {
        relationsOfEntity.splice(idx, 1);
      }
    }
  }

  protected refreshRelationFromPipe(): MonoTypeOperatorFunction<TRelation> {
    return tap<TRelation>(relation => {
      const referenceKey = this.getReferenceKey(relation);
      this.updatableServiceOfEntity?.getDataForUpdate(referenceKey, (data) => {
        this.refreshRelationInternal(data, relation);
      });
    });
  }

  protected refreshDeletedRelationFromPipe(): MonoTypeOperatorFunction<TRelation> {
    return tap<TRelation>(deletedRelation => {
      const referenceKey = this.getReferenceKey(deletedRelation);
      this.updatableServiceOfEntity?.getDataForUpdate(referenceKey, (data) => {
        this.refreshDeletedRelationInternal(data, deletedRelation);
      });
    });
  }
  // #endregion

  // #region Getters
  private setRelationInCache(relation) {
    if (!this.getSingleDataByKeyFromCache(this.getReferenceKey(relation), relation.GetKey())) {
      this.cache.push(relation);
    }
  }

  public getSingleDataByKeyFromCache(referenceKey: TEKey, relationKey: TRKey): TRelation {
    return this.cache.find(r => r.GetKey() == relationKey && this.getReferenceKey(r) == referenceKey)
  }

  public getSingleDataByKey(referenceKey: TEKey, relationKey: TRKey, useLocalCache: boolean = false): Observable<TRelation> {
    this.logger.info(`Get data in ${this.controllerBaseUrl} with referenceKey: ${referenceKey} and key ${relationKey}`);
    return this.getSingleDataByUrl(this.getUrlFromControllerWithReference(referenceKey, relationKey.toString()), useLocalCache);
  }

  public getSingleDataByUrl(url: string, useLocalCache: boolean = false): Observable<TRelation> {
    return this.getSingleDataByUrlTyped<TRelation>(url, this.constructorType).pipe(
      tap(relation => {
        if (useLocalCache) {
          this.setRelationInCache(relation);
        }
      })
    );
  }

  public getMultipleDataByReferenceKey(referenceKey: TEKey, useLocalCache: boolean = false): Observable<TRelation[]> {
    this.logger.info(`Get data in ${this.controllerBaseUrl} with reference key ${referenceKey}`);
    return this.getMultipleDataByUrl(this.getUrlFromControllerWithReference(referenceKey)).pipe(
      tap(relations => {
        // Set in parent cache
        this.getReferencesDataInCache(referenceKey, (data) => {
          this.setRelationsFromEntity(data, relations);
        });
        // Set in local cache
        if (useLocalCache) {
          relations.forEach(relation => {
            this.setRelationInCache(relation);
          });
        }
      })
    );
  }

  public getMultipleDataByKeys(referenceKeys: TEKey[], relationKeys: TRKey[], useLocalCache: boolean = false): Observable<TRelation[]> {
    if (referenceKeys.length > 0 && relationKeys.length > 0) {
      this.logger.info(`Get data in ${this.controllerBaseUrl} with keys ${referenceKeys} - ${relationKeys}`);
      let queryParams = referenceKeys.map((k) => `referenceKeys=${k}`).join('&');
      queryParams += '&' + relationKeys.map((k) => `relation1NKeys=${k}`).join('&');
      return this.getMultipleDataByUrl(this.getUrlFromControllerWithReference(undefined, 'keys', queryParams), useLocalCache);
    } else {
      return of<TRelation[]>([]);
    }
  }

  public getMultipleDataByUrl(url: string, useLocalCache: boolean = false): Observable<TRelation[]> {
    return this.getMultipleDataByUrlTyped<TRelation>(url, this.constructorType).pipe(
      tap(relations => {
        // Set in local cache
        if (useLocalCache) {
          relations.forEach(relation => {
            this.setRelationInCache(relation);
          });
        }
      })
    );
  }
  // #endregion
}
