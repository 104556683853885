import { Observable } from 'rxjs';
import { EntityWithKey } from '../../models';
import { NGXLogger } from 'ngx-logger';
import { HttpClientExtended } from '../http-extended/http-client-extended.service';
import { HubsManagmentService } from '../hubs-managment/hubs-managment.service';
import { Getter } from '../getter/getter.service';
import { Constructor } from '../../helpers/constructor';

export class Crud<T extends EntityWithKey<TKey>, TKey extends number | string> extends Getter<T, TKey> {
  constructor(protected http: HttpClientExtended, protected logger: NGXLogger, protected hubsManagment: HubsManagmentService,
              protected controllerBaseUrl: string, protected constructorType: Constructor<T>) { 
    super(http, logger, hubsManagment, controllerBaseUrl, constructorType);
  }

  // #region CRUD
  // #region Create
  public createSingleData(data: T, url?: string): Observable<T> {
    if (!url) {
      url = this.getUrlFromController();
    }
    this.logger.info(`Create data in ${this.controllerBaseUrl}`);
    return this.http.postData(this.constructorType, url, data).pipe(
      this.refreshDataFromPipe()
    );
  }

  public createMultipleData(data: T[], url?: string): Observable<T[]> {
    if (!url) {
      url = this.getUrlFromController(`createMultiple`);
    }
    this.logger.info(`Create multiple data in ${this.controllerBaseUrl}`);
    return this.http.postMultipleData(this.constructorType, url, data).pipe(
      this.refreshMultipleDataFromPipe()
    );
  }
  // #endregion

  // #region Update
  public updateSingleData(data: T, url?: string): Observable<T> {
    if (!url) {
      url = this.getUrlFromController(`${data.GetKey()}`);
    }
    this.logger.info(`Update data in ${this.controllerBaseUrl} with key ${data.GetKey()}`);
    return this.http.postData(this.constructorType, url, data).pipe(
      this.refreshDataFromPipe()
    );
  }

  public updateMultipleData(data: T[], url?: string): Observable<T[]> {
    if (!url) {
      url = this.getUrlFromController('updateMultiple');
    }
    this.logger.info(`Update multiple data in ${this.controllerBaseUrl}`);
    return this.http.postMultipleData(this.constructorType, url, data).pipe(
      this.refreshMultipleDataFromPipe()
    );
  }
  // #endregion
  
  // #region Delete
  public deleteSingleData(key: TKey, url?: string): Observable<T> {
    if (!url) {
      url = this.getUrlFromController(`${key}`);
    }
    this.logger.info(`Delete data in ${this.controllerBaseUrl} with key ${key}`);
    return this.http.deleteData(this.constructorType, url).pipe(
      this.refreshDeletedDataFromPipe()
    );
  }

  public deleteMultipleData(keys: TKey[], url?: string): Observable<T[]> {
    if (!url) {
      const queryParams = keys.map((key) => `keys=${key}`).join('&');
      url = this.getUrlFromController(`?${queryParams}`);
    }
    this.logger.info(`Delete multiple data in ${this.controllerBaseUrl}`);
    return this.http.deleteMultipleData(this.constructorType, url).pipe(
      this.refreshMultipleDeletedDataFromPipe()
    );
  }
  // #endregion
  // #endregion CRUD
}
