import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ControlContainer, UntypedFormGroup } from "@angular/forms";
import { MatSelectChange } from "@angular/material/select";
import { map, Observable } from "rxjs";
import { EntityWithKey } from "../../../models/entity-with-key.model";


@Component({
  selector: "frmk-dropdownlist-field",
  templateUrl: "./dropdownlist-field.component.html",
  styleUrls: ["./dropdownlist-field.component.scss"],
})
export class DropdownlistFieldComponent implements OnInit {
  formGroup: UntypedFormGroup;
  @Input() label: string;
  @Input() controlName: string;
  @Input() list$: Observable<EntityWithKey<number | string>[]>;
  @Input() emptyText: string;
  @Input() newItemText: string;
  @Output() selectionChange = new EventEmitter<MatSelectChange>();
  @Output() addItem = new EventEmitter();

  constructor(private controlContainer: ControlContainer) {}
  
  get control() { 
    return this.formGroup.get(this.controlName);
  }

  ngOnInit() {
    this.formGroup = <UntypedFormGroup>this.controlContainer.control;
  }

  onSelectionChange(e: any) {
    if (e.value == -1) { // No replace by ===
      this.addItem.emit();
    } else {
      this.selectionChange.emit(e);
    }
  }

  getItem(id: number | string): Observable<EntityWithKey<number | string>> {
    return this.list$.pipe(
      map(items => items.find(item => item.GetKey() === id)),
    );
  }
}
