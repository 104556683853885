// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#header-home {
  margin-bottom: 2em;
}

#role-container {
  align-self: flex-start;
  margin-left: 2rem;
}

h2 {
  font-size: 20px;
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./projects/Apps/Semae/src/app/pages/home-page/home-page.component.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;AADF;;AAIA;EACE,sBAAA;EACA,iBAAA;AADF;;AAIA;EACE,eAAA;EACA,gBAAA;AADF","sourcesContent":["@import \"../../styles/variables.scss\";\r\n\r\n#header-home {\r\n  margin-bottom: 2em;\r\n}\r\n\r\n#role-container {\r\n  align-self: flex-start;\r\n  margin-left: 2rem;\r\n}\r\n\r\nh2 {\r\n  font-size: 20px;\r\n  text-align: left;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
