import { Component, Input } from '@angular/core';
import { AuthentificationService } from 'angular-helpers';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-bottom-nav',
  templateUrl: './bottom-nav.component.html',
  styleUrls: ['./bottom-nav.component.scss']
})
export class BottomNavComponent {
  @Input() pages: [];
  @Input() isLoggedIn$: Observable<boolean>;

  constructor(private authService: AuthentificationService) {}

  isPageVisible (page): boolean {
    if (page.data) {
      if (!page.data.neededRole || this.authService.roles && this.authService.roles.includes(page.data.neededRole)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
