import { Observable } from 'rxjs';
import { Entity } from '../../models';
import { NGXLogger } from 'ngx-logger';
import { HttpClientExtended } from '../http-extended/http-client-extended.service';
import { Constructor } from '../../helpers/constructor';

export class ControllerFromApi {

  constructor(protected http: HttpClientExtended, protected logger: NGXLogger, protected controllerBaseUrl: string) { 
  }

  protected getUrlFromController(subPath?: string, queryParams?: string | any, path?: string): string {
    if (!path) {
      path = `${this.controllerBaseUrl}`;      
    }
    if (subPath) {
      path += '/' + subPath;
    }
    if (queryParams) {
      if (typeof(queryParams) === 'string') {
        path += '?' + queryParams;
      } else {
        const params = new URLSearchParams();
        for (const key in queryParams) {
          if (queryParams.hasOwnProperty(key)) {
            params.set(key, queryParams[key]);
          }
        }
        path += '?' + params.toString();
      }
    }
    return path;
  }

  // #region Getters
  public getSingleDataByUrlTyped<T extends Entity>(url: string, constructorType: Constructor<T>): Observable<T> {
    this.logger.debug(`Get single data with ${url}`);
    const obsResult$ = this.http.getData(constructorType, url);

    return obsResult$;
  }

  public getMultipleDataByUrlTyped<T extends Entity>(url: string, constructorType: Constructor<T>): Observable<T[]> {
    this.logger.debug(`Get data list from ${url}`);
    const obsResult$ = this.http.getMultipleData(constructorType, url);
    return obsResult$;
  }
  // #endregion
}
