import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ILoginJwtDialogComponent } from '../../interfaces/login-jwt-dialog.interface';

@Component({
  selector: 'frmk-login-jwt-dialog',
  templateUrl: './login-jwt-dialog.component.html',
  styleUrls: ['./login-jwt-dialog.component.scss']
})
export class LoginJwtDialogComponent implements ILoginJwtDialogComponent {

  constructor(private dialogRef: MatDialogRef<LoginJwtDialogComponent>) {
  }

  loginSuccess() {
    this.dialogRef.close();
  }
}
