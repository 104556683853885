// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .cdk-overlay-pane {
  max-width: 98vw !important;
}

.dialog-container {
  display: flex;
  flex-direction: column;
  padding: 0.5em;
}

h3 {
  margin-top: 0;
}

mat-dialog-actions {
  display: flex;
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./projects/Apps/Semae/src/app/dialogs/dialogs.scss"],"names":[],"mappings":"AAEA;EACE,0BAAA;AADF;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,cAAA;AADF;;AAIA;EACE,aAAA;AADF;;AAIA;EACE,aAAA;EACA,yBAAA;AADF","sourcesContent":["@import \"../styles/variables.scss\";\r\n\r\n::ng-deep .cdk-overlay-pane {\r\n  max-width: 98vw!important;\r\n}\r\n\r\n.dialog-container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  padding: 0.5em;\r\n}\r\n\r\nh3 {\r\n  margin-top: 0;\r\n}\r\n\r\nmat-dialog-actions {\r\n  display: flex;\r\n  justify-content: flex-end;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
