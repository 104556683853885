import { Injectable } from '@angular/core';
import Keycloak, { KeycloakInitOptions, KeycloakLoginOptions, KeycloakLogoutOptions, KeycloakPromise, KeycloakTokenParsed } from 'keycloak-js';
import { BehaviorSubject, forkJoin, from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import KeycloakAuthorization from 'keycloak-js/dist/keycloak-authz';
import { Config } from '../../config';
import { AssetsHelperService } from '../../helpers/assets-helper.service';


@Injectable({
  providedIn: 'root',
})
export class KeycloakTokenService {

  private keycloak?: Keycloak;
  private authorization?: KeycloakAuthorization;
  private isReadySubject$ = new BehaviorSubject<boolean>(false);
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();
  public isReady$ = this.isReadySubject$.asObservable();
  config: Config

  constructor(private assetsHelperService: AssetsHelperService) {
  }

  get isAuthenticated(): boolean {
    return this.keycloak?.authenticated ?? false;
  }

  setConfigParams(config: Config) {
    this.config = config;
  }

  get username(): string | undefined {
    return this.currentAccessTokenParsed.preferred_username;
  }

  get refreshToken(): string {
    return this.keycloak?.refreshToken;
  }

  public init(): Observable<any> {
    const openIdConnectOptions = {
      url: this.config.authKeycloakServer,
      realm: this.config.authKeycloakRealm,
      clientId: this.config.authKeycloakClientId,
    };

    this.keycloak = new Keycloak(openIdConnectOptions);
    const initKeycloak = this.initKeycloak(this.keycloak)
      .pipe(
        switchMap(isAuthenticated =>
          forkJoin([this.initAuthorization(), this.loadUserProfile(isAuthenticated)])
        )
      );

    return initKeycloak;
  }

  public getAccessToken(): Observable<string> {
    if (!this.keycloak)
      throw Error('Keycloak authentication service not initialized');

    const accessToken = from(this.keycloak.updateToken(5))
    //   .pipe(mergeMap(() => this.getRptToken()));
      .pipe(map(() => this.currentAccessToken));

    return accessToken;
  }

  public get currentAccessToken(): string {
    return this.keycloak.token;
  }

  public get hasToken(): boolean {
    return !!this.currentAccessToken;
  }

  public isTokenExpired(minValidity?: number): boolean {
    return this.keycloak.isTokenExpired();
  }

  public get currentAccessTokenParsed(): KeycloakTokenParsed {
    return this.keycloak.tokenParsed;
  }

  public login(options?: KeycloakLoginOptions): KeycloakPromise<void, void> {

    console.log('### TokenService LOGIN', this.keycloak);
    if (!this.keycloak)
      throw Error('Keycloak authentication service not initialized');
    return this.keycloak.login(options);
  }

  public logout(options?: KeycloakLogoutOptions): KeycloakPromise<void, void> {
    console.log('### TokenService LOGOUT', this.keycloak);
    if (!this.keycloak)
      throw Error('Keycloak authentication service not initialized');
    if (!options?.redirectUri) {
      if (!options) {
        options = {};
      }
      options.redirectUri = this.assetsHelperService.getBaseUrl();
    }
    return this.keycloak.logout(options);
  }

  private initKeycloak(keycloak: Keycloak): Observable<any> {
    const initOptions: KeycloakInitOptions = { onLoad: 'check-sso', silentCheckSsoRedirectUri: this.assetsHelperService.getBaseUrl() + 'assets/silent-check-sso.html' };
    return from(keycloak.init(initOptions).then((authenticated) => {
      if (authenticated) {
        console.log("Key cloak authenticated: " + keycloak.userInfo);
        this.isAuthenticatedSubject$.next(true);
        this.isReadySubject$.next(true);
      } else {
        console.log("Key cloak not authenticated");
        this.isAuthenticatedSubject$.next(false);
        this.isReadySubject$.next(true);
      }
    }));
  }

  private initAuthorization(): Observable<void> {
    if (!this.keycloak)
      throw Error('Keycloak authentication service not initialized');

    this.authorization = new KeycloakAuthorization(this.keycloak);
    this.authorization.init();
    return of(void 0);
  }

  private loadUserProfile(isAuthenticated: boolean): Observable<any> {
    return isAuthenticated ? from(this.keycloak!.loadUserProfile()) : of(undefined);
  }

  getUserRoles(allRoles: boolean = true): string[] {
    let roles: string[] = [];
    if (this.keycloak.resourceAccess) {
      for (const key in this.keycloak.resourceAccess) {
        if (this.keycloak.resourceAccess.hasOwnProperty(key)) {
          const resourceAccess = this.keycloak.resourceAccess[key];
          const clientRoles = resourceAccess['roles'] || [];
          roles = roles.concat(clientRoles);
        }
      }
    }

    if (allRoles && this.keycloak.realmAccess) {
      const realmRoles = this.keycloak.realmAccess['roles'] || [];
      roles.push(...realmRoles);
    }
    return roles;
  }

  // private getRptToken(): Observable<string> {
  //   return new Observable((observer: Observer<string>) => {
  //     if (!this.authorization)
  //       throw Error('Keycloak authorization service not initialized');

  //     this.authorization
  //       .entitlement(this.config.authKeycloakResourceId)
  //       .then(
  //         rptToken => {
  //           observer.next(rptToken);
  //           observer.complete();
  //         },
  //         () => observer.error('Authorization request was denied by the server.'),
  //         () => observer.error('Could not obtain authorization data from server.')
  //       );
  //   });
  // }
}
