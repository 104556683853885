import { AuthConfig } from 'angular-oauth2-oidc';
import { ConfigurationProvider } from '../config';

export function resolveAuthIS4Config(configService: ConfigurationProvider): AuthConfig {
  let href = document.location.href;
  let idxOfNav = href.indexOf('/nav/');
  if (idxOfNav >= 0) {
    href = href.substr(0, idxOfNav);
  }
  if (!document.location.href.endsWith('/')) {
    href = href + '/';
  }

  const config: AuthConfig = {
    requireHttps: false,
    issuer: (configService.params ? configService.params.authIS4Api : undefined),
    clientId: (configService.params ? configService.params.authIS4ClientId : undefined),
    scope: (configService.params ? configService.params.authIS4Scope : undefined),
    responseType: 'code',
    redirectUri: href + 'is4-redirect.html',
    silentRefreshRedirectUri: href + 'is4-silent-refresh.html',
    useSilentRefresh: true, // Needed for Code Flow to suggest using iframe-based refreshes
    silentRefreshTimeout: (configService.params ? configService.params.authIS4SilentRefreshTimeout : undefined), // For faster testing
    timeoutFactor: (configService.params ? configService.params.authIS4TimeoutFactor : undefined), // For faster testing
    sessionChecksEnabled: true,
    showDebugInformation: true, // Also requires enabling "Verbose" level in devtools
    clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
    nonceStateSeparator : 'semicolon' // Real semicolon gets mangled by IdentityServer's URI encoding (used by is4-redirect.html)
  };
  return config;
}
