// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    #spinner {
      z-index: 10;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 5em;
      display: flex;
      background: rgba(255, 255, 255, 0.8);
      align-items: center;
      justify-content: center;
    }
    .mat-spinner-color::ng-deep circle{
      stroke: #686868 !important;
    }
  `, "",{"version":3,"sources":["webpack://./projects/Apps/Semae/src/app/components/loader.component.ts"],"names":[],"mappings":";IACI;MACE,WAAW;MACX,WAAW;MACX,kBAAkB;MAClB,MAAM;MACN,OAAO;MACP,WAAW;MACX,aAAa;MACb,oCAAoC;MACpC,mBAAmB;MACnB,uBAAuB;IACzB;IACA;MACE,0BAA0B;IAC5B","sourcesContent":["\n    #spinner {\n      z-index: 10;\n      width: 100%;\n      position: absolute;\n      top: 0;\n      left: 0;\n      bottom: 5em;\n      display: flex;\n      background: rgba(255, 255, 255, 0.8);\n      align-items: center;\n      justify-content: center;\n    }\n    .mat-spinner-color::ng-deep circle{\n      stroke: #686868 !important;\n    }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
