import { Component, Input, OnInit } from "@angular/core";
import { ControlContainer, UntypedFormGroup } from "@angular/forms";
import { StringHelper } from "../../../helpers/string.helper";

@Component({
  selector: "frmk-url-field",
  templateUrl: "./url-field.component.html",
  styleUrls: ["./url-field.component.scss"],
})
export class UrlFieldComponent implements OnInit {
  formGroup: UntypedFormGroup;
  @Input() label: string;
  @Input() controlName: string;

  constructor(private controlContainer: ControlContainer) {}

  get control() { 
    return this.formGroup.get(this.controlName);
  }

  public isValidUrl() {
    const url = this.formGroup.get(this.controlName).toString();
    if (StringHelper.isNullOrEmpty(url)) {
      return true;
    } else {
      const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.%]+$/;
      const regex = new RegExp(urlRegex);
      return regex.test(url);
    }
  }

  ngOnInit() {
    this.formGroup = <UntypedFormGroup>this.controlContainer.control;
  }
}
