import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class IconsServiceHelper {

  constructor(
    private matIconRegistery: MatIconRegistry,
    private domSanitizer: DomSanitizer) { }

  public registerIconSet(iconEnum: object, path: string): void {
    this.loadIcons(
      Object.values(iconEnum),
      path
    );
  }

  private loadIcons(iconsKey: string[], iconUrl: string): void {
    iconsKey.forEach(key => {
      this.matIconRegistery.addSvgIcon(
        key,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`${iconUrl}/${key}.svg`));
    });
  }
}
