import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApisHttpClientExtended } from '../apis-http-client-extended/apis-http-client-extended.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(protected http: ApisHttpClientExtended) { }

  public testAuthorization(): Observable<boolean> {
    return this.http.getFromExampleApi(`WithAuthentification/TestAuthorization`);
  }
}
