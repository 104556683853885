// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#parent-layout-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#layout-container {
  height: 100%;
}

.logo {
  background-color: white;
}

.logo img {
  height: auto;
  width: 10%;
}

.menu-item {
  height: 3em;
  font-size: 12px;
  line-height: 3em;
  text-indent: 15px;
  text-transform: uppercase;
  font-weight: bold;
  color: #717171;
  border: 1px solid white;
}

.menu-sub-item, .menu-item-bottom {
  height: 2.5em;
  font-size: 12px;
  line-height: 2.5em;
  text-indent: 15px;
  text-transform: none !important;
  color: #717171;
  background-color: white;
}

.menu-sub-item {
  border: 1px solid white;
}

.menu-sub-item i.glyphicon {
  float: right;
  margin-right: 10px;
  color: #e2001a;
  line-height: inherit;
}

.menu-item:hover, .menu-sub-item:hover, .menu-item-bottom:hover {
  color: black;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./projects/Apps/Semae/src/app/app.component.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;AADJ;;AAIA;EACI,YAAA;AADJ;;AAIA;EACI,uBAAA;AADJ;;AAGA;EACI,YAAA;EACA,UAAA;AAAJ;;AAGA;EACI,WAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,yBAAA;EACA,iBAAA;EACA,cAAA;EACA,uBAAA;AAAJ;;AAGA;EACI,aAAA;EACA,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,+BAAA;EACA,cAAA;EACA,uBAAA;AAAJ;;AAGA;EACI,uBAAA;AAAJ;;AAGA;EACI,YAAA;EACA,kBAAA;EACA,cAAA;EACA,oBAAA;AAAJ;;AAGA;EACI,YAAA;EACA,eAAA;AAAJ","sourcesContent":["@import \"./styles/variables.scss\";\r\n\r\n#parent-layout-container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    width: 100%;\r\n    height: 100%;\r\n    overflow: hidden;\r\n}\r\n\r\n#layout-container {\r\n    height: 100%;\r\n}\r\n\r\n.logo {\r\n    background-color: white;\r\n}\r\n.logo img {\r\n    height: auto;\r\n    width: 10%;\r\n}\r\n\r\n.menu-item {\r\n    height: 3em;\r\n    font-size: 12px;\r\n    line-height: 3em;\r\n    text-indent: 15px;\r\n    text-transform: uppercase;\r\n    font-weight: bold;\r\n    color: #717171;\r\n    border: 1px solid white;\r\n}\r\n\r\n.menu-sub-item, .menu-item-bottom {\r\n    height: 2.5em;\r\n    font-size: 12px;\r\n    line-height: 2.5em;\r\n    text-indent: 15px;\r\n    text-transform: none !important;\r\n    color: #717171;\r\n    background-color: white;\r\n}\r\n\r\n.menu-sub-item {\r\n    border: 1px solid $bg-color;\r\n}\r\n\r\n.menu-sub-item i.glyphicon {\r\n    float: right;\r\n    margin-right: 10px;\r\n    color: #e2001a;\r\n    line-height: inherit;\r\n}\r\n\r\n.menu-item:hover, .menu-sub-item:hover, .menu-item-bottom:hover {\r\n    color:black;\r\n    cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
