import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER, Injectable, isDevMode, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PagesModule } from './pages.module';
import { AppDirectivesModule } from './directives.module';
import { ServicesModule } from './services.module';
import { AppComponent } from '../app.component';
import { AppComponentsModule } from './components.module';
import { AppDialogsModule } from './dialogs.module';
import { INGXLoggerConfig, LoggerModule, NgxLoggerLevel, TOKEN_LOGGER_CONFIG } from 'ngx-logger';
import { Config } from 'projects/Apps/Semae/src/configs/config';
import { ApisConfig, ApisConfigurationProvider, ApisServicesModule, AppMaterialModule } from 'apis-helpers';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { MsalModule } from '@azure/msal-angular';
registerLocaleData(localeFr);

/**
 * Use to update config of library after loaded in APP_INITIALIZER
 */
@Injectable({ providedIn: 'root' })
export class ConfigFromApp extends ApisConfigurationProvider {
  constructor(private configStore: Config) {
    super();
  }

  get params(): ApisConfig {
    return this.configStore.params;
  }
}

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppMaterialModule,
    ScrollingModule,
    ServicesModule,
    PagesModule,
    AppComponentsModule,
    AppDialogsModule,
    AppDirectivesModule,
    HttpClientModule,
    MsalModule,
    LoggerModule.forRoot(undefined, {
      configProvider: {
        provide: TOKEN_LOGGER_CONFIG,
        useFactory: resolveNgxLoggerConfig
      }
    }),
    ToastrModule.forRoot(),
    ApisServicesModule.forRoot({
      config: {
        provide: ApisConfigurationProvider,
        useClass: ConfigFromApp
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 10 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
      scope: './'
    })
  ],
  declarations: [
    AppComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: (configService: Config) => () => configService.loadConfig(), deps: [Config], multi: true },
    { provide: LOCALE_ID, useValue: 'fr-FR'},
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {}

export function resolveNgxLoggerConfig(): INGXLoggerConfig  {
  const config = {
    serverLoggingUrl: (Config.params ? Config.getApiUrl(Config.params.logsApi, 'logs') : undefined),
    level: NgxLoggerLevel.DEBUG,
    serverLogLevel: NgxLoggerLevel.INFO
  };
  return config;
}

