import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AuthJwtGuard } from './guards/auth-jwt.guard';
import { AuthJwtService } from './services/auth-jwt.service';
import { LoginJwtGuard } from './guards/login-jwt.guard';
import { LoginJwtComponent } from './components/login-jwt/login-jwt.component';
import { LoginJwtDialogComponent } from './dialogs/login-jwt-dialog/login-jwt-dialog.component';
import { DirectivesModule } from '../modules/directives.modules';

@NgModule({
    declarations: [
        LoginJwtComponent,
        LoginJwtDialogComponent,
        LoginJwtDialogComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        AuthJwtService,
        AuthJwtGuard,
        LoginJwtGuard
    ],
    imports: [
        CommonModule,
        RouterModule,
        HttpClientModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        DirectivesModule
    ],
    exports: [
        LoginJwtComponent,
        LoginJwtDialogComponent,
        ReactiveFormsModule
    ]
})
export class AuthJwtModule { }
