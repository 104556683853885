import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatDateFormats, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateFieldComponent, DateRangeFieldComponent, DropdownlistFieldComponent, NumberFieldComponent, TextAreaFieldComponent, TextFieldComponent, UrlFieldComponent } from "./forms";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextFieldModule } from "@angular/cdk/text-field";
import { CommonModule } from "@angular/common";
import { MatInputModule } from "@angular/material/input";
import { CurrencyFieldComponent } from "./forms/currency-field/currency-field.component";
import { IntegerFieldComponent } from "./forms/integer-field/integer-field.component";
import { MatSelectModule } from "@angular/material/select";

export const MY_FORMATS: MatDateFormats = {
  parse: {
      dateInput: 'DD/MM/YYYY',
  },
  display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'L',
      monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ],
  imports: [
    CommonModule,
    // MatFormFieldModule, 
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatMomentDateModule,
    FormsModule,
    ReactiveFormsModule,
    TextFieldModule 
  ],
  declarations: [
    DateFieldComponent,
    DateRangeFieldComponent,
    IntegerFieldComponent,
    CurrencyFieldComponent,
    NumberFieldComponent,
    DropdownlistFieldComponent,
    TextFieldComponent,
    UrlFieldComponent,
    TextAreaFieldComponent
  ],
  exports: [
    DateFieldComponent,
    DateRangeFieldComponent,
    IntegerFieldComponent,
    CurrencyFieldComponent,
    NumberFieldComponent,
    DropdownlistFieldComponent,
    TextFieldComponent,
    UrlFieldComponent,
    TextAreaFieldComponent
  ]
})
export class ComponentsModule { }
